import {
  GET_ALL_JOBS,
  GET_TOKEN_DETAILS,
  EMPTY_STATUS,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAILURE,
  FILTER_DROPDOWN_DATA,
  PURCHASE_TOKEN_SUCCESS,
  PURCHASE_TOKEN_FAILURE,
  SELECTED_JOB_SUCCESS,
  SELECTED_JOB_FAILURE,
  CLOSE_JOB_SUCCESS,
  CLOSE_JOB_FAILURE,
  GET_STATUS_LIST,
  JOB_STATUS_SUCCESS,
  JOB_STATUS_FAILURE,
  FILTER_DROPDOWN_COUNTRY,
  FILTER_DROPDOWN_LOCATION,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_FAILURE,
} from "../../constants/action-types";
import { successAlert } from "../../constants/helper";
import { request } from "../../services/http-service";

export const getAllJobs = (status, count = 999) => {
  const postJobObject = {
    jobPostingStatus: status,
    pageSize: count,
  };
  return async (dispatch) => {
    const jobSummary = await request(
      "cced/jobs/all/status",
      "post",
      postJobObject,
      true,
      dispatch
    );

    if (jobSummary.status === "ok") {
      dispatch({ type: GET_ALL_JOBS, data: jobSummary.jobs });
    }
  };
};

export const getTokenDetails = (payload) => async (dispatch) => {
  try {
    const response = await request(
      "cced/jobs/token/details",
      "GET",
      null,
      false
    );

    if (response.status === "ok")
      dispatch({ type: GET_TOKEN_DETAILS, data: response.tokenDetails });
    else {
      return response.errorMessage;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const createJob = (createJobObj) => {
  return (dispatch) => {
    return request(
      "cced/jobs/job/add",
      "POST",
      createJobObj,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: CREATE_JOB_SUCCESS });
      } else {
        dispatch({ type: CREATE_JOB_FAILURE });
      }
    });
  };
};

export const editJob = (createJobObj) => {
  return (dispatch) => {
    return request(
      "cced/jobs/job/edit",
      "POST",
      createJobObj,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        successAlert("Job Edited");
        dispatch({ type: EDIT_JOB_SUCCESS });
        return res;
      } else {
        dispatch({ type: EDIT_JOB_FAILURE });
      }
    });
  };
};

export const getJobFilters = () => {
  return (dispatch) => {
    return request(
      "utility/data/job/dropdowns",
      "get",
      "",
      false,
      dispatch
    ).then((res) => {
      if (res) {
        dispatch({ type: FILTER_DROPDOWN_DATA, data: res });
      } else {
      }
    });
  };
};

export const getCountryDropdown = (payload) => async (dispatch) => {
  // setTimeout(async () => {
  //   const response = await request(
  //     "cced/jobs/by-id",
  //     "POST",
  //     selectedJobObject,
  //     true
  //   );

  //   response.status === "ok"
  //     ? dispatch({ type: SELECTED_JOB_SUCCESS, data: response })
  //     : dispatch({ type: SELECTED_JOB_FAILURE });
  // }, 1);

  try {
    setTimeout(async () => {
      const response = await request(
        "utility/data/locations/countries",
        "get",
        "",
        false,
        dispatch
      );

      if (response) {
        response.locationsData = response.locationsData.sort((a, b) =>
          a.value.localeCompare(b.value)
        );

        if (response.locationsData.length > 0)
          dispatch({
            type: FILTER_DROPDOWN_COUNTRY,
            data: response.locationsData,
          });
      }
    }, 1);
  } catch (error) {
    console.error(error);
  }
};

export const getLocationDropdown = (id) => {
  return (dispatch) => {
    return request(
      `utility/data/locations/cities/${id}`,
      "get",
      "",
      false,
      dispatch
    ).then((res) => {
      if (res) {
        dispatch({ type: FILTER_DROPDOWN_LOCATION, data: res.locationsData });
      } else {
      }
    });
  };
};

export const emptyStatus = () => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_STATUS,
    });
  };
};

export const purchaseToken = (data) => {
  return (dispatch) => {
    return request("cced/jobs/token/buy", "POST", data, true, dispatch).then(
      (res) => {
        if (res.status === "ok") {
          dispatch({ type: PURCHASE_TOKEN_SUCCESS });
          return res;
        } else {
          dispatch({ type: PURCHASE_TOKEN_FAILURE });
          return res;
        }
      }
    );
  };
};

export const getSelectedJobById = (jobId) => (dispatch) => {
  const selectedJobObject = { jobId };

  setTimeout(async () => {
    const response = await request(
      "cced/jobs/by-id",
      "POST",
      selectedJobObject,
      true
    );

    response.status === "ok"
      ? dispatch({ type: SELECTED_JOB_SUCCESS, data: response })
      : dispatch({ type: SELECTED_JOB_FAILURE });
  }, 1);
};

export const closeJobById = (closeJobObject) => {
  return (dispatch) => {
    return request(
      "cced/jobs/job/close",
      "POST",
      closeJobObject,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: CLOSE_JOB_SUCCESS, data: res });
        return res.status;
      } else {
        dispatch({ type: CLOSE_JOB_FAILURE });
      }
    });
  };
};

export const getJobStatuses = () => {
  return (dispatch) => {
    return request(
      "cced/jobs/job/applicant/statuses",
      "GET",
      "",
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: GET_STATUS_LIST, data: res["statuses"] });
      }
    });
  };
};

export const changeApplicantStatus = (applicantStatus) => {
  return (dispatch) => {
    return request(
      "cced/jobs/job/applicant/status/update",
      "POST",
      applicantStatus,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: JOB_STATUS_SUCCESS, data: res });
      } else {
        dispatch({ type: JOB_STATUS_FAILURE });
      }
    });
  };
};
