import Header from "../components/create_job/header";
import Form from "../components/create_job/form";
import Container from "react-bootstrap/Container";
import { UpdateActiveMenu } from "../utils/update_active";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CreateANewJob = (props) => {
  const navigate = useNavigate();

  const tokens = 10;
  useEffect(() => {
    if (tokens === 0) navigate("/billing");
  }, []);

  UpdateActiveMenu("sl-jobs");

  return (
    <div className="content">
      <Container fluid>
        <Header></Header>
        <Form user={props.user} />
      </Container>
    </div>
  );
};

export default CreateANewJob;
