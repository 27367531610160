import React, { useEffect } from "react";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Stripe = ({
  actions,
  isLoggedIn,
  status,
  tokenId,
  tokenCount,
  totalPrice,
  preBuyTokenInfo,
  discountId,
}) => {
  return (
    <>
      {preBuyTokenInfo && (
        <Elements
          stripe={stripePromise}
          options={{
            currency: "myr",
            mode: "payment",
            amount: preBuyTokenInfo.totalAmount,
          }}
        >
          <CheckoutForm
            tokenId={tokenId}
            clientSecret={preBuyTokenInfo.paymentIntentSecret}
            tokenCount={tokenCount}
            tokenPrice={preBuyTokenInfo.totalAmount}
            discountId={discountId}
          />
        </Elements>
      )}
    </>
  );
};
const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  status: props.userReducer.status,
  tokenCount: parentProps.tokenCount,
  totalPrice: parentProps.totalPrice,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);
