import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TitleBar from "../headers/title_bar";

const Header = ({ jobData }) => {
  const headerData = {
    subNotBold: "",
    subBold: "Edit Job",
    description: jobData?.title,
    buttons: false,
  };

  return <TitleBar data={headerData} />;
};

export default Header;
