import Card from "./card";
import { allJobs } from "../../utils/jobs";
import Empty from "../commons/empty";
import React, { useEffect, useState } from "react";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const Listing = ({ actions, jobs }) => {
  const listing = allJobs();
  const [jobListing, setJobListing] = useState([]);
  const emptyData = {
    msg: "You have no job created yet. Click the button below to create new.",
    btn: {
      link: "/jobs/create-a-new-job",
      text: "Create New Job",
    },
  };
  useEffect(() => {
    setJobListing(jobs);
  }, [jobs]);
  useEffect(() => {
    actions.getAllJobs("OPEN", 9);
  }, []);
  const List = () => {
    return (
      <>
        <div className="jobs-main mt-4">
          {jobListing.map((job, i) => (
            <Card key={i} job={job} />
          ))}
        </div>
      </>
    );
  };

  return (
    <>{listing.length > 0 ? <List></List> : <Empty data={emptyData}></Empty>}</>
  );
};

const mapStateToProps = (props) => ({
  jobs: props.jobReducer.jobListing,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
