import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Card = ({ job }) => {
  return (
    <Link
      to={"/job/" + job.id}
      className="d-inline-block py-5 p-4 border-radius-15 grey-bg text-decoration-none"
    >
      <div className="d-flex align-items-start justify-content-between">
        <div className="job-card-title d-flex flex-column align-items-baseline justify-content-center">
          <h5 className="font-24 font--inter-semiBold blue-text mb-2">
            {job.title}
          </h5>
        </div>
        <img src="/assets/icons/right.png" alt="Right" />
      </div>
      <Row className="w-100 mx-auto bg">
        {job?.employmentTypeCode && (
          <Col xs={8}>
            <p className="font-16 job-meta d-block blue-text">
              <span
                className="d-inline-block p-2 rounded font--inter-semiBold me-2"
                data-type={job.employmentTypeCode
                  .replace(/_/gi, " ")
                  .toLowerCase()}
              >
                {job.employmentTypeCode.replace(/_/gi, " ").toLowerCase()}
              </span>
            </p>
          </Col>
        )}
        {job.tokenTypeCode && (
          <Col xs={4}>
            <p className="font-16 job-meta d-block blue-text">
              <span
                className="d-inline-block p-2 rounded font--inter-semiBold me-2"
                data-type={
                  job?.tokenTypeCode === "PREMIUM" ? "premium" : "basic"
                }
              >
                {job?.tokenTypeCode === "PREMIUM" ? "Premium" : "Basic"}
              </span>
            </p>
          </Col>
        )}
      </Row>

      <hr />

      <Row>
        <Col
          xs={4}
          className="p-1 d-flex flex-wrap align-items-start justify-content-center"
        >
          <p className={"font-24 mb-0 blue-text font--inter-semiBold"}>
            {job.totalApplicantsCount}
          </p>
          <span className="d-block w-100 text-center text-secondary font-12 mt-2">
            New
            <br />
            Applications
          </span>
        </Col>
        <Col
          xs={4}
          className="p-1 d-flex flex-wrap align-items-start justify-content-center"
        >
          <p className={"font-24 mb-0 blue-text font--inter-semiBold"}>
            {job.shortlistedApplicantsCount}
          </p>
          <span className="d-block w-100 text-center text-secondary font-12 mt-2">
            Shortlisted
          </span>
        </Col>
        <Col
          xs={4}
          className="p-1 d-flex flex-wrap align-items-start justify-content-center"
        >
          <p className={"font-24 mb-0 blue-text font--inter-semiBold"}>
            {job.newApplicantsCount}
          </p>
          <span className="d-block w-100 text-center text-secondary font-12 mt-2">
            Total
            <br />
            Applicants
          </span>
        </Col>
      </Row>
    </Link>
  );
};

export default Card;
