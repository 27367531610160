import { Col } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import useProgression from "../../hooks/useProgression";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../../redux/actions/userAction";

const AdditionalInfo = ({ setCanProceed }) => {
  const { handleProgressionData } = useProgression();

  const [additionalInfoData, setAdditionalInfoData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleResetError = () => {
    setErrorMessage();
    setCanProceed(true);
  };

  useEffect(() => {
    const step4Data = handleProgressionData.get("step 4");
    !additionalInfoData && setAdditionalInfoData(step4Data);

    additionalInfoData &&
      handleProgressionData.set("step 4", {
        ...step4Data,
        ...additionalInfoData,
        companyInfoData: additionalInfoData,
      });
  }, [additionalInfoData]);

  useEffect(() => {
    errorMessage && setCanProceed(false);
  }, [errorMessage]);

  return (
    <Col xs={12} className="p-5 justify-content-center">
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Website
        </label>
        <input
          type="text"
          className="form-control"
          id="websiteUrl"
          placeholder="Enter your website address here"
          value={additionalInfoData?.websiteUrl || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setAdditionalInfoData({
              ...additionalInfoData,
              websiteUrl: value,
            });
          }}
        />
      </div>
    </Col>
  );
};

const mapStateToProps = (props) => ({
  user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
