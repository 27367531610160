import Container from "react-bootstrap/Container";
import Header from "./header";
import JobEditForm from "./edit_job_form";
import { UpdateActiveMenu } from "../../utils/update_active";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const JobEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const jobData = location?.state?.jobData;

  UpdateActiveMenu("sl-jobs");

  useEffect(() => {
    if (!jobData) {
      navigate("/jobs", { replace: true });
    }
  }, [jobData]);

  return (
    jobData && (
      <div className="content">
        <Container fluid>
          <Header jobData={jobData} />
          <JobEditForm jobData={jobData} />
        </Container>
      </div>
    )
  );
};

export default JobEdit;
