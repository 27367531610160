import { Link } from 'react-router-dom';
import TitleBar from '../headers/title_bar';

export default function Header(props) {
    const headerData = {
        "subNotBold": "",
        "subBold": "Create a new job",
        "description": "Please fill in the details of your job below for it to be posted!",
        "buttons": false
    };

    return (
        <>
            <Link to="/jobs" className='mb-4 font-18 blue-text text-decoration-none font--inter-semiBold d-flex align-items-center'>
                <img src="/assets/icons/left.png" alt="left" className='me-2' /> Back to Jobs
            </Link>
            <TitleBar data={headerData}></TitleBar>
        </>
    );
}