import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Sidebar from "../sidebar/Sidebar";
// import Notifications from "../headers/notifications";

const DashboardLayout = ({ actions, user, employer }) => {
  const [show, setShow] = useState(true);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGetScreenWidth = useCallback(() => {
    const innerWidth = window.innerWidth;

    innerWidth > Number(850) ? setShow(true) : setShow(true);

    setScreenWidth(innerWidth);
  }, [window.innerWidth, setScreenWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleGetScreenWidth);

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    screenWidth !== 0 && screenWidth < 850 ? setShow(false) : setShow(true);
  }, [screenWidth]);

  return (
    <main className="grey-bg main min-vh-100">
      <Container fluid>
        <Row>
          <Col
            className={
              "col-auto position-sticky sticky-top show p-3 px-4 blue-bg vh-100 " +
              (show ? "active" : "inactive")
            }
            data-show={show ? "show" : "hide"}
            id="sidebar"
          >
            <div className="d-flex align-items-center flex-row justify-content-around mt-3 mb-4">
              <div className="sidebar-logo">
                <a href="/dashboard">
                  <img
                    src="/assets/images/logos/logo-white.png"
                    className="active"
                    alt="Logo"
                  />
                  <img
                    src="/assets/images/logos/logo-c.png"
                    className="inactive"
                    alt="Logo"
                  />
                </a>
              </div>
              <button
                className={
                  "menu-toggle-btn rounded p-1 bg-transparent " +
                  (show ? "active" : "inactive")
                }
                onClick={show ? handleClose : handleShow}
              >
                <img
                  src={
                    "/assets/icons/" +
                    (show ? "menu_left.svg" : "menu_right.svg")
                  }
                  alt="Menu"
                  className="w-100"
                />
              </button>
            </div>
            <Sidebar />
          </Col>
          <Col className="p-0" style={{ width: 0 }}>
            <main id="main-content" className="position-relative">
              <div className="header-div position-sticky sticky-top d-flex flex-row align-items-center justify-content-end p-3 bg-white">
                {/* <Notifications /> */}
                <span
                  id="username"
                  className={"font-16 font--inter-semiBold me-3"}
                >
                  {user?.name ?? ""}
                </span>
                <Link to="/account">
                  <img
                    src={
                      (employer || user)?.profileImageUrl ||
                      "/assets/icons/user.png"
                    }
                    alt="User"
                    className="w-50px rounded bg-dark rounded-circle"
                    width={50}
                    height={50}
                  />
                </Link>
              </div>
              <div className={"main-content-container py-5 p-3"}>
                <Outlet />
              </div>
            </main>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  employer: props.userReducer.employer,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
