import { Button, Col, Container } from "react-bootstrap";
import "./assets/style.css";
import useProgression from "./hooks/useProgression";
import Form from "../../components/account/form";
import ProgressionHeader from "./components/ProgressionHeader";
import StepsForms from "./components/StepsForms";
import OnboardingLayout from "./OnboardingLayout";
import useProfile from "../../components/account/hooks/useProfile";
import { useLocation } from "react-router-dom";

const Onboarding = () => {
  const _useProfile = useProfile();
  const _useProgression = useProgression();
  const location = useLocation();

  return (
    <OnboardingLayout>
      <Container className="container px-1 px-md-4 mx-auto">
        <div className="card d-flex">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <ProgressionHeader {..._useProgression} />
              <StepsForms
                {..._useProgression}
                {..._useProfile}
                {...location.state}
              />
            </div>
          </div>
        </div>
      </Container>
    </OnboardingLayout>
  );
};

export default Onboarding;
