import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/job_user/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserDetails from "../components/job_user/user_details";
import UserProjects from "../components/job_user/user_projects";
import Meetings from "../components/job_user/meetings";
import { UpdateActiveMenu } from "../utils/update_active";
import { useEffect, useState } from "react";
import { useAxios } from "../api/useAxios";

export default function JobUser() {
  const params = useParams();
  const navigate = useNavigate();
  const { get } = useAxios();

  const [creativeProfile, setCreativeProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleGetCreative = async () => {
    if (creativeProfile) return;

    const creativeProfileData = await get(
      `creatives/details/${params.user_id}`
    );

    if (creativeProfileData?.status === "ok") {
      const phonePrefix = (await get("utility/data/default")).phoneNumbersData;

      if (creativeProfileData.creativeProfile.phoneNumberDataId) {
        const phoneNumberFull = `(${
          phonePrefix.filter(
            (pp) =>
              pp.id === creativeProfileData.creativeProfile.phoneNumberDataId
          )[0].callingCode
        }) ${creativeProfileData.creativeProfile.phoneNumber}`;

        creativeProfileData.creativeProfile.phoneNumberFull = phoneNumberFull;
      }

      setCreativeProfile(creativeProfileData);
    }
  };

  useEffect(() => {
    !params?.user_id
      ? navigate("/jobs", { replace: true })
      : handleGetCreative();
  }, [params]);

  if (errorMessage)
    return (
      <div className="text-center text-danger --text-14">{errorMessage}</div>
    );

  UpdateActiveMenu("sl-jobs");

  const job_id = parseInt(params.job_id);
  const user_id = parseInt(params.user_id);

  const user = {};
  const projects = {};
  const meetings = {};

  if (!creativeProfile) return <></>;

  return (
    <div className="content">
      <Container fluid>
        <Header />
        <Row>
          <Col xs={12} md={6}>
            <div className={"p-3 py-4 bg-white border-radius-15 mt-4"}>
              <UserDetails data={creativeProfile} />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={"p-3 py-4 bg-white border-radius-15 mt-4"}>
              <UserProjects data={creativeProfile} />
            </div>
          </Col>
        </Row>
        {/* <Row>
            <Col>
              <div className={"p-3 py-4 bg-white border-radius-15 mt-4"}>
                <Meetings data={meetings} />
              </div>
            </Col>
          </Row> */}
      </Container>
    </div>
  );
}
