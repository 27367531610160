import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Header(props) {
    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <Link to={"/job/" + props?.data?.job_id ?? "1"} className='font-18 blue-text text-decoration-none font--inter-semiBold d-flex align-items-center'>
                        <img src="/assets/icons/left.png" alt="left" className='me-2' /> Back to {props?.data?.job_title ?? "Job title"}
                    </Link>
                </Col>
                <Col className='text-end'>
                    <select id="status" name="status-select" className="form-control w-50 d-inline-block blue-bg text-white">
                        <option value="Pending">Pending</option>
                        <option value="Shortlisted">Shortlisted</option>
                        <option value="Interviewed">Interviewed</option>
                        <option value="Offered">Offered</option>
                        <option value="Notified">Notified</option>
                        <option value="Unsuccessful">Unsuccessful</option>
                    </select>
                </Col>
            </Row>
        </>
    );
}