import { GET_ACTIONS_REQUIRED, GET_WEEKLY_MEETINGS } from "../../constants/action-types";

const initialState = {
	actionsRequired: [],
	status: '',
	meetingData: {}
};

const dashboardReducer = (state = initialState, action) => {
	if (action.type === GET_ACTIONS_REQUIRED) {
		return {
			...state,
			actionsRequired: action.data,
		};
	}
	if (action.type === GET_WEEKLY_MEETINGS) {
		return {
			...state,
			meetingData: action.data,
		};
	}
	return state;
}

export default dashboardReducer;
