import {
  GET_ALL_CREATIVES,
  GET_TRANSACTIONS_HISTORY,
  CREATIVES_PAGINATION,
} from "../../constants/action-types";

const initialState = {
  creativeListing: [],
  status: "",
  transactions: [],
  pagination: {
    pageIndex: 0,
    pageSize: 15,
    totalCount: 0,
  },
};

const creativeReducer = (state = initialState, action) => {
  if (action.type === GET_ALL_CREATIVES) {
    return {
      ...state,
      creativeListing: action.data,
    };
  }
  if (action.type === GET_TRANSACTIONS_HISTORY) {
    return {
      ...state,
      transactions: action.data,
    };
  }
  if (action.type === CREATIVES_PAGINATION) {
    return {
      ...state,
      pagination: action.data,
    };
  }
  return state;
};

export default creativeReducer;
