export const image64ToCanvas = (canvasRef, image64, pixelCrop) => {
  return new Promise((resolve, reject) => {
    const canvas = canvasRef;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.onload = function () {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = Math.ceil(pixelCrop.width * scaleX);
      canvas.height = Math.ceil(pixelCrop.height * scaleY);

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY
      );

      resolve(canvas);
    };

    image.onerror = function (error) {
      reject(error);
    };

    image.src = image64;
  });
};

export const getImage64Extension = (image64) => {
  return image64.substring("data:image/".length, image64.indexOf(";base64"));
};
