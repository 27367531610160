import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../services/firebaseService";
import firebase from "firebase/compat/app"; // Add this import
import { successAlert } from "../../constants/helper";
import { signInWithPopup } from "firebase/auth";

const SocialLogins = ({ actions, setErrorMessage, type }) => {
  const navigate = useNavigate();

  const handleSignInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      const resApple = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSocialSignUp = async (data, type, auth) => {
    navigate("/onboarding", { replace: true, state: { data, type, auth } });
  };

  const onLoggedOn = () => {
    successAlert("User logged in successfully");
    setTimeout(() => {
      navigate("/dashboard", { replace: true });
    }, 1);
  };

  const signInWithFirebase = useCallback(
    async (provider) => {
      try {
        if (provider?.providerId === "google.com") {
          provider.setCustomParameters({
            prompt: "select_account",
          });
        }

        const res = await auth.signInWithPopup(provider);

        const loginData = {
          email: res?.additionalUserInfo?.profile?.email,
          token: res?.credential?.accessToken,
          localId: res?.user?.multiFactor?.user?.uid,
          providerId:
            res?.credential?.providerId?.split(".")[0]?.toLowerCase() || "",
        };

        const signUpData = {
          email: res?.additionalUserInfo?.profile?.email,
          password: "",
          name: res?.additionalUserInfo?.profile?.name,
          phoneNumber: "",
          role: "EMPLOYER",
        };

        if (type === "signup")
          handleSocialSignUp(signUpData, "signup", loginData);

        const socialLoginResponse = await actions.loginUserSocial(
          loginData,
          true
        );

        if (socialLoginResponse === "ok") onLoggedOn();
        else {
          socialLoginResponse.errorMessage === "User not found"
            ? handleSocialSignUp(signUpData, "signup", loginData)
            : setErrorMessage(socialLoginResponse.errorMessage);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onLoggedOn]
  );

  return (
    <div className="d-flex">
      <button
        className="border-0 p-2 bg-transparent social-login-btn"
        type="button"
        onClick={() => signInWithFirebase(provider)}
      >
        <img
          src="/assets/icons/social-login-icons/Facebook.svg"
          alt="facebook social login"
        />
      </button>

      <button
        className="border-0 p-2 bg-transparent social-login-btn"
        type="button"
        onClick={() =>
          signInWithFirebase(new firebase.auth.GoogleAuthProvider())
        }
      >
        <img
          src="/assets/icons/social-login-icons/Google.svg"
          alt="google social login"
        />
      </button>

      {/* <button
        className="border-0 p-2 bg-transparent social-login-btn"
        type="button"
        onClick={() => handleSignInWithApple()}
      >
        <img
          src="/assets/icons/social-login-icons/Apple.svg"
          alt="apple social login"
        />
      </button> */}
    </div>
  );
};

export default SocialLogins;
