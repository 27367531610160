import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../api/useAxios";
import { useEffect, useState } from "react";

export default function UserDetails({ data: { creativeProfile } }) {
  const params = useParams();

  if (!creativeProfile) return <></>;

  return (
    <>
      <p className="font-32 font--inter-semiBold blue-text mb-3">
        {creativeProfile?.name ?? ""}
      </p>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div>
          <a
            className="text-decoration-none blue-bg px-3 py-2 text-white rounded blue-border me-2"
            href={`${process.env.REACT_APP_CCWL_REDIRECT_URI}/profile/${params.user_id}`}
            target="_blank"
            rel="noreferrer"
          >
            View profile
          </a>
          <a
            className="text-decoration-none blue-bg px-3 py-2 text-white rounded blue-border me-2"
            href={`${process.env.REACT_APP_CCWL_REDIRECT_URI}/profile/${params.user_id}`}
            target="_blank"
            rel="noreferrer"
          >
            View resume
          </a>
        </div>
        {/* <span className="font-14 text-secondary">
          {props?.data?.applied_text ?? "Applied a week ago"}
        </span> */}
      </div>
      <p className="font-24 font--inter-semiBold text-black mt-4 mb-3">
        Personal Details
      </p>
      <p className="font-16 font--inter-regular text-black mb-2">
        <img src="/assets/icons/phone.png" alt="phone" className="me-2" />
        {`(+60) ${creativeProfile?.phoneNumber}`}
      </p>
      <p className="font-16 font--inter-regular text-black mb-2">
        <img src="/assets/icons/envelope.png" alt="envelope" className="me-2" />
        {creativeProfile?.email}
      </p>
    </>
  );
}
