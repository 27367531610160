import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Stripe from "../../components/billing/stripe";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as userActions from "../../redux/actions/userAction";
import * as Actions from "../../redux/actions/jobAction";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PURCHASE_TOKEN_SUCCESS } from "../../constants/action-types";
import { request } from "../../services/http-service";
import { Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAxios } from "../../api/useAxios";
import { errorAlert } from "../../constants/helper";
import BootstrapModal from "../BootstrapModal";

const Purchase = ({ actions, userActions, isLoggedIn, status, tokens }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isRedirected = location?.state?.isRedirected;
  const { post } = useAxios();
  const [show, setShow] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showStripeResponse, setShowStripeResponse] = useState(false);
  const [stripeResponseMsg, setStripeResponseMsg] = useState("");
  const [tokenPrice, setTokenPrice] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [key, setKey] = useState("Basic");
  const [promoCode, setPromoCode] = useState();
  const [isPurchasing, setIsPurchasing] = useState(false);

  let [amountBasic, setAmountBasic] = useState(0);
  let [amountPremium, setAmountPremium] = useState(0);

  const tncRef = useRef(null);
  const promoCodeRef = useRef(null);

  const { tokenDetails } = useSelector(({ jobReducer }) => jobReducer);
  const [preBuyTokenInfo, setPreBuyTokenInfo] = useState();
  const [isInsufficientToken, setIsInsufficientToken] = useState(
    location?.state?.isInsufficientToken
  );

  const handleDirectPurchase = async (data) => {
    const res = await actions.purchaseToken(data);

    if (res?.status === "ok") {
      const tokenBought = key === "Basic" ? amountBasic : amountPremium;

      setStripeResponseMsg(
        tokenBought + (tokenBought > 1 ? " tokens " : " token ") + "purchased!"
      );

      await userActions.getUserTokens();
      actions.emptyStatus();
      handleClose();
      handleCloseStripe();
      handleShowStripeResponse();
    } else {
      errorAlert(res?.errorMessage || "Token purchase failed");
    }
  };

  const handleGetPreBuyTokenDetails = async () => {
    const preBuyTokenDetails = await request(
      "cced/jobs/token/pre-buy",
      "POST",
      {
        amount: !promoCode
          ? tokenPrice * (key === "Basic" ? amountBasic : amountPremium)
          : promoCode?.dicountedAmount > 0
          ? promoCode?.dicountedAmount
          : 0,

        tokenId: tokenDetails.find((td) => td.tokenName === key).tokenId,
        discountId: promoCode?.id,
        tokensBought: key === "Basic" ? amountBasic : amountPremium,
      },
      true,
      null
    );

    if (preBuyTokenDetails.status === "ok") {
      promoCode?.dicountedAmount < 1
        ? handleDirectPurchase({
            tokensBought: key === "Basic" ? amountBasic : amountPremium,
            tokenId: tokenDetails.find((td) => td.tokenName === key).tokenId,
            amount: 0,
            transactionId: preBuyTokenDetails.response.paymentIntentId,
            discountId: promoCode?.id,
          })
        : setPreBuyTokenInfo(preBuyTokenDetails.response);
    }
  };

  useEffect(() => {
    const tokenBought = key === "Basic" ? amountBasic : amountPremium;

    if (status === PURCHASE_TOKEN_SUCCESS) {
      setStripeResponseMsg(
        tokenBought + (tokenBought > 1 ? " tokens " : " token ") + "purchased!"
      );
      actions.emptyStatus();
      handleClose();
      handleCloseStripe();
      handleShowStripeResponse();
      userActions.getUserTokens();
    }
  }, [status]);

  useEffect(() => {
    if (tokenDetails) {
      setTokenPrice(
        tokenDetails?.find((td) => td.tokenName === key)?.tokenPrice
      );
    } else {
      actions.getTokenDetails();
    }
  }, [key, tokenDetails, tokenPrice]);

  function incrementAmount(tokenType) {
    tokenType === "Basic"
      ? setAmountBasic(amountBasic + 1)
      : setAmountPremium(amountPremium + 1);
  }

  useEffect(() => {
    handleCheckPromoCode();
  }, [amountBasic, amountPremium]);

  function decrementAmount(tokenType) {
    if (tokenType === "Basic" && amountBasic !== 0) {
      setAmountBasic(amountBasic < 0 ? 0 : amountBasic - 1);
    }

    if (tokenType === "Premium" && amountPremium !== 0) {
      setAmountPremium(amountPremium < 0 ? 0 : amountPremium - 1);
    }
  }

  const handleCheckPromoCode = async () => {
    if (!promoCodeRef?.current?.value) return;

    setIsPurchasing(true);
    const promoCodeData = await post("discounts/discount/validate", {
      discountCode: promoCodeRef.current.value,
      amount: tokenPrice * (key === "Basic" ? amountBasic : amountPremium),
      tokenTypeId: tokenDetails.find((td) => td.tokenName === key).tokenId,
    });

    if (!promoCodeData?.discount?.id) {
      setErrorMessage(promoCodeData?.errorMessage);
      setSuccessMessage("");
    } else {
      setPromoCode(promoCodeData.discount);
      setSuccessMessage("Congratulations! Coupon code applied successfully");
    }
  };

  useEffect(() => {}, [amountBasic, amountPremium, promoCodeRef]);

  const handleClose = () => setShow(false);

  const handleValidatePurchase = async () => {
    if (!tncRef.current.checked)
      setErrorMessage("Please accept the Terms and Conditions");
    else if (promoCodeRef?.current?.value) {
      await handleCheckPromoCode();
    } else {
      handleCheckPromoCode();
      setErrorMessage(null);
      setShow(true);
    }
  };

  const handleCloseStripe = () => setShowStripe(false);
  const handleShowStripe = () => setShowStripe(true);

  const handleCloseStripeResponse = () => {
    setShowStripeResponse(false);

    isInsufficientToken
      ? setShowContinueCreateJob(true)
      : window.location.reload(false);
  };
  const handleShowStripeResponse = () => setShowStripeResponse(true);

  const openStripe = async () => {
    await handleGetPreBuyTokenDetails();
    handleClose();
    handleShowStripe();
  };

  const handleCheck = () => {
    setCheckbox(!checkbox);
  };

  const purchaseRef = useRef(null);

  const scrollToBottom = () => {
    const el = document.querySelector(
      "#main-content > .main-content-container > div > div > div:nth-child(4)"
    );
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    isRedirected && scrollToBottom();
  }, [isRedirected]);

  const [showContinueCreateJob, setShowContinueCreateJob] = useState(false);

  return (
    <>
      <BootstrapModal
        title="Continue create new job?"
        children={
          <div className="d-flex flex-column">
            <span>You have sufficient balance on create new job now.</span>
            <span>
              Click <span className="--font-weight-900">confirm</span> to start
              create new job...
            </span>
          </div>
        }
        footer={true}
        show={showContinueCreateJob}
        onHide={() => setShowContinueCreateJob(false)}
        toggleShow={setShowContinueCreateJob}
        confirmCallback={() =>
          navigate("/jobs/create-a-new-job", { replace: true })
        }
        footerBtnLeftCallback={() => {
          setShowContinueCreateJob(false);
        }}
      />
      <Modal
        className={"status-modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            Purchase Tokens
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          {(key === "Basic" ? amountBasic : amountPremium) === 0 ? (
            <>
              <p className="font-16 blue-text text-center mb-4">
                {key === "Basic" ? amountBasic : amountPremium} tokens selected,
                please select token to continue.
              </p>
              <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
                <button
                  onClick={handleClose}
                  className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="font-16 blue-text text-center mb-4">
                Confirm purchase {key === "Basic" ? amountBasic : amountPremium}{" "}
                tokens?
              </p>
              <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
                <button
                  onClick={handleClose}
                  className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
                >
                  Cancel
                </button>
                <button
                  onClick={openStripe}
                  className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className={"status-modal"}
        show={showStripe}
        onHide={handleCloseStripe}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            Purchase Tokens
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          {preBuyTokenInfo && (
            <Stripe
              tokenId={tokenDetails.find((td) => td.tokenName === key).tokenId}
              tokenCount={key === "Basic" ? amountBasic : amountPremium}
              totalPrice={
                promoCode?.dicountedAmount
                  ? preBuyTokenInfo.totalAmount
                  : tokenPrice * (key === "Basic" ? amountBasic : amountPremium)
              }
              preBuyTokenInfo={preBuyTokenInfo}
              discountId={promoCode?.id}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className={"status-modal"}
        show={showStripeResponse}
        onHide={handleCloseStripeResponse}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            {stripeResponseMsg}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
            <button
              onClick={handleCloseStripeResponse}
              className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {isInsufficientToken &&
        tokens?.balance?.gold < 1 &&
        tokens?.balance?.diamond < 1 && (
          <div className="container-insufficient-tokens">
            <span className="">Insufficient Balance</span>
            <span>
              You have insufficient balance on creating new job. Please purchase
              more tokens to create a new job.
            </span>
          </div>
        )}

      <Row
        className={`${
          isInsufficientToken &&
          tokens?.balance?.gold < 1 &&
          tokens?.balance?.diamond < 1 &&
          "--border-solid-2-ef4444"
        } dashboard-title-row p-5 bg-white border-radius-15 my-4`}
      >
        <Col className="p-3 text-center border-right-1px-solid-grey">
          <div ref={purchaseRef} />
          <Tabs
            id="token-tabs"
            activeKey={key}
            onSelect={(k) => {
              key === "Basic" ? setAmountBasic(0) : setAmountPremium(0);
              setPromoCode();
              setKey(k);
            }}
            className="mb-3 jobs-tabs filter-tabs"
            fill
          >
            <Tab eventKey="Basic" title="Basic Token" className="py-4">
              <img
                src="/assets/icons/tokens/token-basic-lg.png"
                className="w-75px mb-4"
                alt="Basic Token"
                height={100}
                width={100}
              />
              <div className="count-token-div d-flex justify-content-center align-items-center flex-row mb-3">
                <button
                  className="btn-decrement bg-transparent rounded font-24 blue-text border-3px-solid"
                  onClick={() => decrementAmount("Basic")}
                >
                  -
                </button>
                <input
                  className="count-token-basic mx-2 text-center font-24 border-3px-solid rounded font--inter-semiBold"
                  type="number"
                  id="count-token"
                  onChange={({ currentTarget: { value } }) => {
                    value = value === "" ? 0 : value;
                    /^\d+$/.test(value) && setAmountBasic(parseInt(value));
                  }}
                  value={Number(amountBasic).toString()}
                  onMouseEnter={() => {
                    // document.querySelector(
                    //   ".main-content-container"
                    // ).style.overflowY = "hidden";
                    document.querySelector(".count-token-basic").focus();
                  }}
                  onMouseLeave={() => {
                    document.querySelector(
                      ".main-content-container"
                    ).style.overflowY = "auto";
                    // document.querySelector(".count-token-basic").blur();
                  }}
                />
                <button
                  className="btn-increment bg-transparent rounded font-24 blue-text border-3px-solid"
                  onClick={() => incrementAmount("Basic")}
                >
                  +
                </button>
              </div>
              {/* <p className="count-token-text p-3 grey-bg rounded font-16 font--inter-regular blue-text">
                Get up to{" "}
                <span className="font--inter-semiBold">50% discount</span> if
                you purchased more than 10 tokens!
              </p> */}
            </Tab>

            <Tab eventKey="Premium" title="Premium Token" className="py-4">
              <img
                src="/assets/icons/tokens/token-premium-lg.png"
                className="w-75px mb-4"
                alt="Premium Token"
                height={100}
                width={100}
              />
              <div className="count-token-div d-flex justify-content-center align-items-center flex-row mb-3">
                <button
                  className="bg-transparent rounded font-24 blue-text border-3px-solid"
                  onClick={() => decrementAmount("Premium")}
                >
                  -
                </button>
                <input
                  className="count-token-premium mx-2 text-center font-24 border-3px-solid rounded font--inter-semiBold"
                  type="number"
                  id="count-token"
                  min={0}
                  onChange={({ currentTarget: { value } }) => {
                    value = value === "" ? 0 : value;
                    /^\d+$/.test(value) && setAmountPremium(parseInt(value));
                  }}
                  value={Number(amountPremium).toString()}
                  onMouseEnter={() => {
                    document.querySelector(
                      ".main-content-container"
                    ).style.overflow = "hidden";
                    document.querySelector(".count-token-premium").focus();
                  }}
                  onMouseLeave={() => {
                    document.querySelector(
                      ".main-content-container"
                    ).style.overflow = "auto";
                    document.querySelector(".count-token-premium").blur();
                  }}
                />
                <button
                  className="bg-transparent rounded font-24 blue-text border-3px-solid"
                  onClick={() => incrementAmount("Premium")}
                >
                  +
                </button>
              </div>
            </Tab>
          </Tabs>
        </Col>
        <Col className="p-3">
          {tokenPrice && (
            <div className="w-75 mx-auto">
              <h4 className="font--inter-semiBold font-24 mb-4">Summary</h4>
              <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
                <span className="font--inter-regular">Amount of tokens</span>
                <span className="font--inter-semiBold">
                  {key === "Basic" ? amountBasic : amountPremium}
                </span>
              </p>
              <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
                <span className="font--inter-regular">Price</span>
                <span className="font--inter-semiBold">
                  RM
                  {tokenPrice *
                    (key === "Basic" ? amountBasic : amountPremium) || 0}
                </span>
              </p>
              <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
                <span className="font--inter-regular">Discount</span>
                <span className="font--inter-semiBold">
                  - RM
                  {promoCode?.dicountedAmount < 1
                    ? tokenPrice *
                      (key === "Basic" ? amountBasic : amountPremium)
                    : tokenPrice *
                        (key === "Basic" ? amountBasic : amountPremium) -
                        promoCode?.dicountedAmount || 0}
                </span>
              </p>
              <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
                <span className="font--inter-regular">Total</span>
                <span className="font--inter-semiBold">
                  RM
                  {promoCode?.dicountedAmount
                    ? promoCode?.dicountedAmount < 1
                      ? 0
                      : promoCode?.dicountedAmount
                    : tokenPrice *
                        (key === "Basic" ? amountBasic : amountPremium) || 0}
                </span>
              </p>

              <div
                className="count-token-div mb-3 w-100"
                style={{
                  width: "100%",
                }}
              >
                <input
                  className="mx-2 text-center font-16 rounded font--inter-semiBold"
                  ref={promoCodeRef}
                  onChange={({ currentTarget: { value } }) => {
                    setErrorMessage("");
                    setPromoCode(value);
                  }}
                  onBlur={() => handleCheckPromoCode()}
                  type="text"
                  id="promo-code"
                  name="promo-code"
                  placeholder="Enter Promo Code Here"
                  style={{
                    height: "41px",
                    width: "100%",
                  }}
                />
              </div>

              <div className="form-check my-4">
                <input
                  onChange={() => {
                    setErrorMessage("");
                    handleCheck();
                  }}
                  className="form-check-input"
                  type="checkbox"
                  id="agreement"
                  ref={tncRef}
                />
                <label className="form-check-label" htmlFor="agreement">
                  <span>I have understood the </span>
                  <Link
                    to="https://www.cultcreative.asia/terms-and-conditions"
                    target="_blank"
                    className="text-decoration-none"
                  >
                    terms and conditions
                  </Link>
                  <span> of the implementation of the job.</span>
                </label>
                <div className="text-danger font-12">{errorMessage}</div>

                {!errorMessage && (
                  <div className="text-success font-12">{successMessage}</div>
                )}
              </div>
              <button
                // disabled={!isPurchasing}
                className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                onClick={() => {
                  handleValidatePurchase();
                  !errorMessage && tncRef.current.checked && setShow(true);
                }}
              >
                Purchase
              </button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  status: props.jobReducer.status,
  tokens: props.userReducer.tokens,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
