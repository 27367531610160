import Container from 'react-bootstrap/Container';
import Header from '../components/jobs/header';
import Listing from '../components/jobs/listing';
import { UpdateActiveMenu } from '../utils/update_active';

export default function Jobs() {

    UpdateActiveMenu("sl-jobs");

    return (
        <>
            <div className="content">
                <Container fluid>
                    <Header></Header>
                    <Listing></Listing>
                </Container>
            </div>
        </>
    );
}