import React, { useEffect, useState } from "react";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useAxios } from "../../api/useAxios";

const TitleBar = ({ actions, job, jobId }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const onCloseJob = async () => {
    const closeJobObject = {
      jobId: jobId,
      rating: rating,
      reviewRemarks: comment,
    };
    const isJobClosedSuccess = await actions.closeJobById(closeJobObject);

    if (isJobClosedSuccess) {
      handleClose();
      navigate("/jobs", { replace: true });
    }
  };
  const onValueChange = (e) => {
    setComment(e.target.value);
  };

  if (!job) return <></>;

  return (
    <>
      <Modal
        className={"status-modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body className={"p-4"}>
          <p className="font-20 blue-text font--inter-semiBold mb-2">
            How satisfied are you with Cult Creative?
          </p>
          <div className="star-rating-div mb-4">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={"star-rating " + (index <= rating ? "on" : "off")}
                  onClick={() => setRating(index)}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
          <p className="font-20 blue-text font--inter-semiBold mb-2">
            How satisfied are you with Cult Creative?
          </p>
          <div className="mb-4">
            <table className="close-job-reason-tbl">
              <tbody>
                <tr>
                  <td>I hired someone for this role</td>
                  <td>
                    <input
                      onChange={onValueChange}
                      type={"radio"}
                      name="reason"
                      value="I hired someone for this role"
                    />
                  </td>
                </tr>
                <tr>
                  <td>I got enough participants</td>
                  <td>
                    <input
                      onChange={onValueChange}
                      type={"radio"}
                      name="reason"
                      value="I got enough participants"
                    />
                  </td>
                </tr>
                <tr>
                  <td>I did not receive enough quality applicants</td>
                  <td>
                    <input
                      onChange={onValueChange}
                      type={"radio"}
                      name="reason"
                      value="I did not receive enough quality applicants"
                    />
                  </td>
                </tr>
                <tr>
                  <td>I'm no longer hiring</td>
                  <td>
                    <input
                      onChange={onValueChange}
                      type={"radio"}
                      name="reason"
                      value="I'm no longer hiring"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-row align-items-start justify-content-between p-0 header-buttons mb-2">
            <button
              className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
              onClick={onCloseJob}
            >
              Close Job
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Row className={"dashboard-title-row"}>
        <Col xs={12} lg={6} className="header-text d-flex align-items-end">
          <h4 className="font-40 mb-3 font--inter-bold d-inline-flex flex-row align-items-center blue-text">
            {job?.details?.title}
          </h4>
        </Col>
        <Col
          xs={12}
          lg={6}
          className={
            "d-flex flex-row align-items-start justify-content-end header-buttons mb-2"
          }
        >
          <button
            onClick={handleShow}
            className={
              "me-3 text-decoration-none bg-transparent px-3 py-2 red-text rounded font--inter-semiBold red-border"
            }
          >
            Close Job
          </button>
          {job?.details?.applicantsCount < 1 && (
            <Link
              className={
                "text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
              }
              to={`/job/edit/${job?.details.jobId}`}
              state={{ jobData: job?.details }}
            >
              Edit Job
            </Link>
          )}
          <Link
            className={
              "mx-3 mr-0 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
            }
            to="/jobs/create-a-new-job"
          >
            Create a new job
          </Link>
        </Col>
      </Row>

      <p className="font-16 job-meta d-block blue-text">
        <span
          className="d-inline-block p-2 rounded font--inter-semiBold me-2"
          data-type={job?.details?.tokenTypeId == "1" ? "basic" : "premium"}
        >
          {job?.details?.tokenTypeId == "1" ? "Basic" : "Premium"}
        </span>
      </p>
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  // job: parentProps.job,
  job: props.jobReducer.selectedJob,
  jobId: parentProps.jobId,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
