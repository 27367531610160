import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import { LOGOUT_SUCCESS } from "../../constants/action-types";

const Sidebar = ({ actions, isLogged, user, status }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLogged && status === LOGOUT_SUCCESS) {
      actions.emptyStatus();
      navigate("/", { replace: true });
    }
  }, [status]);

  const logout = () => {
    actions.logoutAction();
  };

  const menu = [
    {
      link: "/dashboard",
      img: "/assets/icons/dashboard.png",
      title: "Dashboard",
    },
    {
      link: "/jobs",
      img: "/assets/icons/jobs.png",
      title: "Jobs",
    },
    // {
    //     "link": "/creatives",
    //     "img": "/assets/icons/creatives.png",
    //     "title": "Creatives"
    // },
    {
      link: "/billing",
      img: "/assets/icons/billing.png",
      title: "Billing",
    },
    {
      link: "/account",
      img: "/assets/icons/user.png",
      title: "Account",
    },
    {
      link: process.env.REACT_APP_CCWL_REDIRECT_URI,
      img: "/assets/icons/eye-white.png",
      title: "See Cult Creative’s job board",
      target: "_blank",
    },
  ];

  const [url, setUrl] = useState(window.location.pathname);

  function updateActive(link) {
    setUrl(link);
  }

  return (
    <>
      <div id="sidebar-main">
        <div className={"sidebar-menu-items position-relative"}>
          {menu.map((item, ind) => {
            return (
              <div key={ind}>
                <Link
                  target={item?.target}
                  key={ind}
                  id={"sl-" + item.title.toLowerCase()}
                  onClick={() => !item?.target && updateActive(item.link)}
                  to={item.link}
                  className={
                    "text-decoration-none text-white py-2 px-3 rounded sidebar-menu-item d-flex align-items-center font-18 mb-3 " +
                    (url.toString().includes(item.link) ? "active" : "")
                  }
                >
                  <img
                    src={item.img}
                    alt={item.title}
                    className={"me-3"}
                    width={20}
                    height={20}
                  />
                  <span className={"font--inter-semiBold"}>{item.title}</span>
                </Link>
              </div>
            );
          })}
          <div className="sidebar-bottom w-100 position-absolute bottom-0 pb-3">
            <div className="support-div px-2 py-3 mb-3 position-sticky sticky-bottom text-center rounded">
              <Link to="dashboard">
                <img
                  className="mb-1"
                  src="/assets/icons/bulb.png"
                  alt="Support"
                />
              </Link>
              <p className="font-20 text-white font--inter-semiBold mb-2">
                Need some help?
              </p>
              <p className="font-16 text-white mb-2">
                <Link
                  className="text-white"
                  to="https://www.cultcreative.asia/faq"
                  target="_blank"
                >
                  Click here
                </Link>{" "}
                to view our resource guides or contact support.
              </p>
            </div>
            <div
              onClick={logout}
              className={
                "cursor-pointer py-2 px-3 rounded sidebar-menu-item d-flex align-items-center font-18 mb-4 text-decoration-none text-white"
              }
            >
              <img
                src="/assets/icons/logout.png"
                alt="Logout"
                className={"me-3"}
              />
              <span className={"font--inter-semiBold"}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
