import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PURCHASE_TOKEN_SUCCESS } from "../../../constants/action-types";
import { useAxios } from "../../../api/useAxios";

const useToken = ({ actions, status, tokens }, isPremium) => {
  const { post } = useAxios();

  const { tokenDetails } = useSelector(({ jobReducer }) => jobReducer);
  const [tokenPrice, setTokenPrice] = useState();
  const [amountToken, setAmountToken] = useState(0);

  const tncRef = useRef(null);

  const incrementAmount = () => {
    setAmountToken(amountToken + 1);
  };

  const decrementAmount = () => {
    if (amountToken !== 0) {
      setAmountToken(amountToken < 0 ? 0 : amountToken - 1);
    }
  };

  const handleGetPreBuyTokenDetails = async (tokenPrice, tokenAmount) => {
    try {
      const preBuyTokenDetails = await post("cced/jobs/token/pre-buy", {
        amount: tokenPrice * tokenAmount,
        tokenId: tokenDetails.find(
          (td) => td.tokenName === (isPremium ? "Premium" : "Basic")
        ).tokenId,
        tokensBought: tokenAmount,
      });

      return preBuyTokenDetails;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tokenDetails) {
      setTokenPrice(
        tokenDetails.find(
          (td) => td.tokenName === (isPremium ? "Premium" : "Basic")
        ).tokenPrice
      );
    } else {
      actions.getTokenDetails();
    }
  }, ["Premium", tokenDetails, tokenPrice]);

  return {
    tokenDetails,
    tokenPrice,
    setTokenPrice,
    amountToken,
    setAmountToken,
    incrementAmount,
    decrementAmount,
    tncRef,
    handleGetPreBuyTokenDetails,
  };
};

export default useToken;
