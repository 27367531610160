import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function SignupLayout(props) {
  return (
    <Container fluid className={"login-layout blue-bg  m-0 p-0"}>
      <Row
        className={
          "vh-100 overflow-auto login-layout-row justify-content-center align-items-center"
        }
      >
        <Col sm={12} md={3} className="vh-100 m-0 p-0">
          <div className="px-5 py-5 w-100 d-flex align-items-center vh-100 bg-white --position-relative">
            {props.children}
          </div>
        </Col>
        <Col className="vh-100 d-flex flex-grow-1 m-0 p-0">
          <div className="px-4 py-5 w-100 d-flex align-items-center --bg-img-signon" />
        </Col>
      </Row>
    </Container>
  );
}
