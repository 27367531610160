import { useNavigate } from "react-router-dom";
import { useAxios } from "../../api/useAxios";
import { useRef, useState } from "react";
import { errorAlert, successAlert } from "../../constants/helper";
import { Row } from "react-bootstrap";

const ResetPasswordForm = ({
  isLoading,
  setIsLoading,
  resetMode,
  setShowResetPasswordModal,
}) => {
  const { post } = useAxios();
  const navigate = useNavigate();

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");

  const handleValidatePasswords = async () => {
    const pass = passwordRef.current.value;
    const confirmPass = confirmPasswordRef.current.value;

    if (pass?.length < 8) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        password: "Password must have at least 8 characters",
      }));
    } else {
      const { password, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }

    if (confirmPass !== pass) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        confirmPass: "Your passwords do not match",
      }));
    } else {
      const { confirmPass, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }
  };

  const handleResetPassword = async () => {
    setErrorMessage("");

    const password = passwordRef.current.value;
    const confirmPass = confirmPasswordRef.current.value;

    if (!password || !confirmPass) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        password: "Password & Confirm Password is required",
        confirmPass: "Password & Confirm Password is required",
      }));
      return;
    }

    if (!errorMessage) {
      setIsLoading(true);
      const res = await post("user-auth/password/reset", {
        oldPassword: password,
        newPassword: password,
      });

      if (res?.status === "ok") {
        successAlert("Password reset successful");
        if (!resetMode) {
          navigate("/login", { replace: true });
        } else {
          setIsLoading(false);
          setShowResetPasswordModal(false);
        }
      } else {
        errorAlert(res?.errorMessage || "Failed to reset password");

        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    }
  };

  return (
    <form className={"ls-form login-form w-100"}>
      <h3 className={"text-center mb-5 fw-700"}>Reset Password</h3>
      <div className="form-group mb-3">
        <input
          ref={passwordRef}
          type="password"
          className="form-control"
          id="password"
          placeholder="New Password *"
          onChange={() => {
            setErrorMessage("");
            handleValidatePasswords();
          }}
        />
        <div className="container-errorMessage text-danger --text-12 pt-2 bg-white">
          {errorMessage?.password}
        </div>
      </div>
      <div className="form-group mb-5">
        <input
          ref={confirmPasswordRef}
          type="password"
          className="form-control"
          id="confirm_password"
          placeholder="Confirm Password *"
          onChange={() => {
            setErrorMessage("");
            handleValidatePasswords();
          }}
        />
        <div className="container-errorMessage text-danger --text-12 pt-2 bg-white">
          {errorMessage?.confirmPass}
        </div>
      </div>
      <Row className="m-0 mb-1 justify-content-center">
        <button
          disabled={isLoading}
          className="p-2 ps-3 pe-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700"
          type="button"
          onClick={handleResetPassword}
        >
          RESET PASSWORD
        </button>
      </Row>
    </form>
  );
};

export default ResetPasswordForm;
