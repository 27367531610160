import {
  COMMON_HEADERS,
  FORM_DATA_HEADERS,
  DEV_URL,
} from "../constants/config";

const request = async (url, method, payload, auth, dataType) => {
  const options = {
    method: method ? method : "GET",
    headers: {
      "Content-Type": "application/json",
    },
    /* headers:
      dataType === "formData" ? { ...FORM_DATA_HEADERS } : { ...COMMON_HEADERS },
       */
  };
  const token = localStorage.getItem("token");
  if (auth && token) {
    options.headers["Authorization"] = "Bearer " + token;
  }

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  const apiUrl = `${DEV_URL}/${url}`;

  try {
    //dispatch({ type: IS_LOADING, payload: true });
    let res = await fetch(apiUrl, options);
    let data = await res.json();

    if (data.status === "ok") {
      //dispatch({ type: IS_LOADING, payload: false });
    } else {
      //dispatch({ type: IS_LOADING, payload: false });
      console.error(res.error);
    }
    return data;
  } catch (error) {
    console.error(error);
  }
};

export { request };
