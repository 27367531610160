import { combineReducers } from "redux";
import userReducer from "./userReducer";
import jobReducer from "./jobReducer";
import creativeReducer from "./creativeReducer";
import dashboardReducer from "./dashboardReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  userReducer,
  jobReducer,
  creativeReducer,
  dashboardReducer,
  utilsReducer,
});

export default rootReducer;
