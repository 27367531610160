import { useState, useEffect, useRef } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

const BuyDiamondTokenForm = ({
  handlePurchase,
  errorMessage,
  setErrorMessage,
  tokenPrice,
  amountToken,
  incrementAmount,
  decrementAmount,
  tncRef,
  isPremium,
}) => {
  const [checkbox, setCheckbox] = useState(false);

  const handleCheck = () => {
    setCheckbox(!checkbox);
  };

  const handleShow = (e) => {
    if (!tncRef.current.checked)
      setErrorMessage("Please accept the Terms and Conditions");
    else {
      setErrorMessage("");
      handlePurchase();
    }
  };

  return (
    <div className="border-left-3px-solid-grey px-3">
      <Col xs={12} className="text-center">
        {}
        <Tabs
          id="token-tabs"
          activeKey="Premium"
          className="mb-3 jobs-tabs filter-tabs"
          fill
        >
          <Tab
            eventKey="Premium"
            title={isPremium ? "Diamond Token" : "Basic Token"}
            className="py-4"
          >
            <img
              src={`/assets/icons/tokens/token-${
                isPremium ? "premium" : "basic"
              }-lg.png`}
              className="w-75px mb-4"
              alt={`${isPremium ? "Premium" : "Basic"} Token`}
              height={100}
              width={100}
            />
            <div className="count-token-div d-flex justify-content-center align-items-center flex-row mb-3">
              <button
                className="btn-decrement bg-transparent rounded font-24 blue-text border-3px-solid"
                onClick={() => decrementAmount()}
              >
                -
              </button>
              <input
                className="mx-2 text-center font-24 border-3px-solid rounded font--inter-semiBold"
                type="number"
                id="count-token"
                min={0}
                value={amountToken}
              />
              <button
                className="btn-increment bg-transparent rounded font-24 blue-text border-3px-solid"
                onClick={() => incrementAmount()}
              >
                +
              </button>
            </div>
          </Tab>
        </Tabs>
      </Col>
      <Col xs={12} className="p-3">
        {tokenPrice && (
          <div className="w-75 mx-auto">
            <h4 className="font--inter-semiBold font-24 mb-4">Summary</h4>
            <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
              <span className="font--inter-regular">Amount of tokens</span>
              <span className="font--inter-semiBold">{amountToken}</span>
            </p>
            <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
              <span className="font--inter-regular">Price</span>
              <span className="font--inter-semiBold">
                RM
                {tokenPrice * amountToken || 0}
              </span>
            </p>
            <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
              <span className="font--inter-regular">Discount</span>
              <span className="font--inter-semiBold">- RM{0}</span>
            </p>
            <p className="font-16 mb-3 blue-text d-flex flex-row justify-content-between">
              <span className="font--inter-regular">Total</span>
              <span className="font--inter-semiBold">
                RM
                {tokenPrice * amountToken || 0}
              </span>
            </p>
            <div className="form-check my-4">
              <input
                onChange={handleCheck}
                className="form-check-input"
                type="checkbox"
                id="agreement"
                ref={tncRef}
              />
              <label className="form-check-label" htmlFor="agreement">
                I have understood the terms and conditions of the implementation
                of the job.
              </label>

              <div className="text-danger text-center font-16 my-3">
                {errorMessage}
              </div>
              {errorMessage &&
                errorMessage !== "Please accept the Terms and Conditions" &&
                errorMessage !== "Invalid amount of token" && (
                  <div className="text-center">
                    or{" "}
                    <Link to="/login" className="text-decoration-none">
                      Sign In
                    </Link>{" "}
                    to make purchase.{" "}
                  </div>
                )}
            </div>
            <button
              type="button"
              className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
              onClick={(e) => handleShow(e)}
            >
              Purchase
            </button>
          </div>
        )}
      </Col>
    </div>
  );
};

export default BuyDiamondTokenForm;
