import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Empty(props) {
    return (
        <>
            <Row className='mx-0 mt-4'>
                <Col className="bg-white border-radius-15 p-5 mb-4 text-center">
                    <img src="/assets/icons/telescope.png" alt="empty" />
                    <p className='text-808080 my-3'>
                        {props?.data?.msg ?? "No data found."}
                    </p>
                    {
                        props.data.btn ?
                            <Link to={props.data.btn.link} className="text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border">
                                {props.data.btn.text}
                            </Link>
                            :
                            ""
                    }
                </Col>
            </Row>
        </>
    );
}