import { useState } from "react";
import { useAxios } from "../../../api/useAxios";

const useValidate = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { post } = useAxios();

  const handleValidateEmail = async (email, password) => {
    const res = await post("user-auth/authenticate", {
      email,
      password,
    });

    if (!res?.errorMessage) {
      setErrorMessage("Email already taken, please use a different one");
      return false;
    } else return true;
  };

  const handleValidateUsername = async (username) => {
    if (username?.length < 3) return false;

    const res = await post("user/username/validate", { username });

    if (!res.isUsernameValid) {
      setErrorMessage("Username already taken, please use a different one");
      return false;
    } else return true;
  };

  return {
    errorMessage,
    setErrorMessage,
    handleValidateEmail,
    handleValidateUsername,
  };
};

export default useValidate;
