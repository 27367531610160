import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import * as Actions from "../../redux/actions/creativeAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Image } from "react-bootstrap";
const Listing = ({ actions, isLoggedIn, status, props, transactionsData }) => {
  const [historyData, setHistoryData] = useState([]);
  const columns = [
    {
      name: "Items",
      center: true,
      selector: (row) => row.tokensCount,
      cell: (row, index, column, id) => {
        return (
          <div className="d-flex align-items-center justify-content-start w-50">
            <div className="d-flex justify-content-start w-100">
              <span
                className={
                  row?.tokensCount < 1 ? "text-danger" : "text-success"
                }
              >
                {row.tokensCount}
              </span>
            </div>
            <Image
              src={
                row?.tokenType === "PREMIUM"
                  ? "/assets/icons/tokens/token-premium-sm.png"
                  : "/assets/icons/tokens/token-basic-sm.png"
              }
              width={24}
              height={24}
            />
          </div>
        );
      },
    },
    {
      name: "Date",
      center: true,
      selector: (row) => row.transactionDate,
    },
    {
      name: "Amount",
      center: true,
      selector: (row) => row.totalAmountPaid,
    },
    {
      name: "Status",
      center: true,
      selector: (row) => row.status,
    },
    {
      name: "Receipt",
      center: true,
      selector: (row) => row.receipt,
      cell: (row, index, column, id) => {
        return (
          <a
            href={row.invoiceUrl}
            target="_blank"
            className="text-black"
            rel="noreferrer"
          >
            View
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    const data = {
      "--startDate": "2023-01-01",
      "--endDate": "2023-03-01",
    };
    actions.getTransactionHistory(data);
  }, []);
  useEffect(() => {
    if (transactionsData) {
      setHistoryData(transactionsData);
    }
  }, [transactionsData]);
  return (
    <>
      <div className="transaction-listing-main py-5 p-4 border-radius-15 bg-white">
        <DataTable
          className="cc-data-table transaction-table"
          columns={columns}
          data={transactionsData}
        />
      </div>
    </>
  );
};
const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  status: props.jobReducer.status,
  transactionsData: props.creativeReducer.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
