import { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import * as Actions from "../../redux/actions/jobAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import BootstrapModal from "../BootstrapModal";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Listing = ({ actions, props, statusesData }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [active, setActive] = useState(false);
  const [checkUsers, addToCheckedUsers] = useState([]);
  const [statusListing, setStatusListing] = useState([]);
  const [currentStatusIndex, setCurrentStatusIndex] = useState();
  const [jobIndex, setJobIndex] = useState();
  const [sUserid, setsUserid] = useState("");
  const [sUsername, setsUsername] = useState("");
  const [sVal, setsVal] = useState(0);
  const [sValLabel, setsValLabel] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchApplicantData, setSearchApplicantData] = useState([]);
  const [selectedResume, setSelectedResume] = useState();

  const handleViewResume = async (cvUrl) => {
    try {
      setSelectedResume(cvUrl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    selectedResume ? setShowResume(true) : setShowResume(false);
  }, [selectedResume]);

  const handleSearchApplicant = useCallback(() => {
    const findIncludes = (field) =>
      field?.toLowerCase()?.includes(searchText?.toLowerCase());

    const { applicants } = props.job;

    const searchResult = applicants.filter(
      ({ applicationStatusValue, email, name, username }) => {
        return (
          applicationStatusValue
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          email?.toLowerCase().includes(searchText?.toLowerCase()) ||
          name?.toLowerCase().includes(searchText?.toLowerCase()) ||
          username?.toLowerCase().includes(searchText?.toLowerCase())
        );
      }
    );

    setSearchApplicantData(searchResult);
  }, [searchText, props.job.applicants]);

  useEffect(() => {
    searchText && handleSearchApplicant();
  }, [searchText]);

  useEffect(() => {
    let elems = document.querySelectorAll(".rdt_TableCol_Sortable span");
    elems.forEach(
      (elem) => (elem.innerHTML = '<img src="/assets/icons/sort.png" alt="" />')
    );
  });
  useEffect(() => {
    actions.getJobStatuses();
  }, []);
  useEffect(() => {
    if (statusesData) {
      setStatusListing(statusesData);
    }
  }, [statusesData]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancel = () => {
    handleClose();
  };

  const expandView = () => {
    setExpanded(!expanded);
  };

  const confirmStatus = () => {
    const applicantStatusObj = {
      jobId: props.jobId,
      applicantId: +sUserid,
      applicantStatusId: sVal,
    };
    actions.changeApplicantStatus(applicantStatusObj);
    const selectedJobIndex = props.job.applicants[jobIndex];
    const value = statusListing[currentStatusIndex];
    selectedJobIndex.applicationStatusCode = value.code;
    handleClose();

    dispatch(Actions.getSelectedJobById(props.jobId));
  };

  const showPopup = (e, id, username, index) => {
    setCurrentStatusIndex(e.target.value);
    const value = statusListing[e.target.value];
    setJobIndex(index);
    setsUserid(id);
    setsUsername(username);
    setsValLabel(value.value);
    setsVal(value.id);
    handleShow();
  };

  const handleCheck = (e) => {
    let user_id = e.target.getAttribute("userid");
    let check = e.target.getAttribute("check");
    let user = e.target.getAttribute("user");
    e.target.classList.toggle("checked");

    if (checkUsers.includes(user_id)) {
      checkUsers.splice(checkUsers.indexOf(user_id), 1);
      e.target.src = user;
    } else {
      checkUsers.push(parseInt(user_id));
      e.target.src = check;
    }

    checkUsers.length > 0 ? setActive(true) : setActive(false);
  };
  const handleOnSearchInputChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  const onClickSearchFilter = () => {
    props.searchData(searchText);
  };
  const getValue = (index) => {
    if (props.job && props.job.applicants && props.job.applicants.length) {
      const jobIndex = !searchText
        ? props.job?.applicants[index]
        : searchApplicantData[index];
      const currentIndex = statusListing
        .map((e) => e.code)
        .indexOf(jobIndex?.applicationStatusCode);
      return currentIndex;
    } else {
      return 0;
    }
  };
  const columns = [
    {
      cell: (row) => {
        return (
          <img
            userid={row.id}
            style={{ borderRadius: "50%", objectFit: "cover" }}
            className="cursor-pointer w-50px h-50px check"
            src={row?.profileImageUrl ?? "/assets/images/wow.png"}
            user={row?.profileImageUrl ?? "/assets/images/wow.png"}
            check="/assets/icons/check-blue.png"
            alt="user-check"
            onClick={(e) => handleCheck(e)}
          />
        );
      },
    },
    {
      name: "Profile",
      center: true,
      selector: (row) => row.name,
      cell: (row, index, column, id) => {
        return (
          <>
            <div>
              <p className="font--inter-semiBold font-20 blue-text mb-2">
                {row.name}
              </p>
              <p className="font--inter-regular font-16 blue-text text-decoration-none mb-0">
                @{row.username}
              </p>
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Contact",
      center: true,
      selector: (row) => row.email,
      cell: (row, index, column, id) => {
        return (
          <>
            <div>
              <p className="font--inter-regular font-16 blue-text text-decoration-none mb-2">
                <img
                  src="/assets/icons/phone.png"
                  alt="phone"
                  className="me-2"
                />{" "}
                {row.phoneNumber}
              </p>
              <p className="font--inter-regular font-16 blue-text text-decoration-none mb-0">
                <img
                  src="/assets/icons/envelope.png"
                  alt="envelope"
                  className="me-2"
                />{" "}
                {row.email}
              </p>
            </div>
          </>
        );
      },
    },

    {
      name: "Applied Date",
      center: true,
      selector: (row) => row.email,
      cell: (row, index, column, id) => {
        return (
          <>
            <div>
              <p className="font--inter-regular font-16 blue-text text-decoration-none mb-2">
                {moment(row.appliedDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
              </p>
            </div>
          </>
        );
      },
    },
    {
      name: "Status",
      center: true,
      selector: (row) => row.applicationStatusValue,
      sortable: true,
      cell: (row, index, column, id) => {
        return (
          <select
            userid={row.id}
            username={row.username}
            value={getValue(index)}
            className={
              "form-control user-job-status " + row.applicationStatusValue
            }
            onChange={(e) => showPopup(e, row.id, row.username, index)}
          >
            {statusListing.map((d, ind) => (
              <option key={ind} value={ind}>
                {d.value}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      name: "Resume",
      center: true,
      selector: (row) => row.resume,
      cell: (row, index, column, id) => {
        return (
          // <Button
          //   onClick={() => handleViewResume(row.cvUrl)}
          //   className="bg-transparent text-black p-0 m-0 border-0 d-flex justify-content-start align-items-center shadow-none"
          // >
          //   <img src="/assets/icons/resume.png" alt="resume" className="me-2" />
          //   View resume
          // </Button>
          <Link
            to={row.cvUrl}
            className="bg-transparent text-black p-0 m-0 border-0 d-flex justify-content-start align-items-center shadow-none"
          >
            <img src="/assets/icons/resume.png" alt="resume" className="me-2" />
            View resume
          </Link>
        );
      },
    },
    {
      center: true,
      cell: (row) => {
        return (
          <Link
            to={`${process.env.REACT_APP_CCWL_REDIRECT_URI}/profile/${row.applicantId}`}
            className="text-black text-decoration-none"
            rel="noreferrer"
            target="_blank"
          >
            <img src="/assets/icons/eye.png" alt="resume" className="me-2" />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <BootstrapModal
        show={showResume}
        handleClose={() => {
          setShowResume(false);
          setSelectedResume();
        }}
      >
        <Button
          className="btn-close-top-left bg-transparent border-0 p-0 m-0 --btn-close-top-left"
          onClick={() => {
            setShowResume(false);
            setSelectedResume();
          }}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-danger bg-white --text-36  rounded-circle"
          />
        </Button>
        <Document
          file={selectedResume}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Page pageNumber={1} />
        </Document>
        {/* <embed src={selectedResume} type="application/pdf" /> */}
        {/* <iframe src={selectedResume + "#toolbar=0"} height={800} width={500} /> */}
      </BootstrapModal>

      <Modal
        className={"status-modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            {sValLabel} Applicant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          <img
            className="d-block mx-auto mb-4"
            src="/assets/icons/tree.png"
            alt="tree"
          />

          <p className="font-16 blue-text text-center mb-4">
            {sValLabel === "Shortlisted"
              ? `Do you wish to confirm changing ${sUsername} to ‘${sValLabel}’? Once confirmed, the applicant will receive an automated email stating that they are shortlisted for this role.`
              : sValLabel === "Unsuccessful"
              ? `Do you wish to confirm changing ${sUsername} to ‘${sValLabel}’? Once confirmed, the applicant will receive an automated email stating that they are unsuccessful for this role.`
              : `Confirm ${sValLabel} ${sUsername} ? Once confirm, the action is
            irreversible.`}{" "}
          </p>
          <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
            <button
              onClick={handleCancel}
              className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
            >
              Cancel
            </button>
            <button
              onClick={confirmStatus}
              className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Row className="justify-content-end">
        <Col xs={3} className={"mb-4 " + (active ? "" : "hide")}>
          <select
            userid={JSON.stringify(checkUsers)}
            username={"Selected"}
            className={"form-control"}
            onChange={(e) => showPopup(e)}
          >
            {statusListing.map((d, index) => (
              <option key={index} value={index}>
                {d.value}
              </option>
            ))}
          </select>
        </Col>
      </Row>

      <div
        className={
          "job-applicants-listing-main py-5 p-4 border-radius-15 bg-white " +
          (expanded
            ? "vh-100 position-absolute top-0 start-0 w-100 z-9999"
            : "position-relative")
        }
      >
        <Row className="align-items-center mb-4">
          <Col xs={8}>
            <input
              value={searchText}
              onChange={(event) => handleOnSearchInputChange(event)}
              type="text"
              name="search"
              placeholder="Search here"
              className="form-control font-16 border-0 border-radius-25 grey-bg"
            />
          </Col>
          <Col xs={3}>
            <p className="font-16 text-808080 m-0 text-end">
              {props.job?.applicants?.length} applicants
            </p>
          </Col>
          <Col className="text-end" xs={1}>
            <button
              className="expand-view button bg-transparent border-0"
              onClick={expandView}
            >
              <img src="/assets/icons/expand.png" alt="expand" />
            </button>
          </Col>
        </Row>

        {!searchText ? (
          <DataTable
            className="cc-data-table job-applicants-table bg-danger d-flex justify-content-center"
            columns={columns}
            data={props.job?.applicants}
          />
        ) : (
          <DataTable
            className="cc-data-table job-applicants-table bg-danger d-flex justify-content-center"
            columns={columns}
            data={searchApplicantData}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  props: parentProps,
  statusesData: props.jobReducer.statusListing,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
