import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import React, { forwardRef, useEffect, useState } from "react";
import * as Actions from "../../../redux/actions/userAction";
import * as utilsActions from "../../../redux/actions/utilsAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import BuyTokenSignUpLayout from "../layouts/BuyTokenSignUpLayout";

import Row from "react-bootstrap/Row";

const BuyTokenSignUpForm = ({
  phonePrefixes,
  register,
  control,
  watch,
  errors,
  handleSubmit,
  handlePurchase,
  selectedPhoneNumberPrefix,
  setSelectedPhoneNumberPrefix,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupOptions = {
    name: { required: "name is required" },
    username: { required: "Username is required" },
    email: { required: "Email is required" },
    phoneNumber: { required: "Phone Number is required" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    confirmPassword: {
      required: "Confirm Password is required",
      validate: (val) => {
        if (watch("password") !== val) {
          return "Your passwords do not match";
        }
      },
    },
  };

  useEffect(() => {
    phonePrefixes?.length < 1 &&
      dispatch(utilsActions.getPhoneNumberPrefixes());
  }, [dispatch]);

  const PhoneNumberSelect = forwardRef(
    (
      {
        phonePrefixes,
        selectedPhoneNumberPrefix,
        setSelectedPhoneNumberPrefix,
        ...props
      },
      ref
    ) => (
      <select
        ref={ref}
        name="phoneNumberDataId"
        defaultValue={selectedPhoneNumberPrefix}
        value={selectedPhoneNumberPrefix}
        className="select-user-profile-phone-prefix bg-transparent"
        onChange={({ currentTarget: { value } }) => {
          setSelectedPhoneNumberPrefix(value);
          props.onChange(value);
        }}
      >
        {phonePrefixes?.length > 0 &&
          phonePrefixes
            .sort((a, b) => a.callingCode - b.callingCode)
            .map((phonePrefix) => (
              <option key={phonePrefix.id} value={phonePrefix.id}>
                {phonePrefix.callingCode}
              </option>
            ))}
      </select>
    )
  );
  return (
    <BuyTokenSignUpLayout>
      <form className={"ls-form signup-form w-100"}>
        <h3 className={"text-center mb-5 fw-700"}>Create an Account</h3>
        <p className={"text-left mb-2 font-20 fw-700"}>Company Information</p>
        <div className="form-group mt-5 mb-3">
          <label className="font--inter-semiBold text-black font-14 mb-2">
            Company Name <span className="text-danger">*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form-control"
            id="name"
            placeholder="Company Name"
            name="name"
            {...register("name", signupOptions.name)}
          />
          <small className="text-danger">
            {errors?.name && errors.name.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label className="font--inter-semiBold text-black font-14 mb-2">
            Username <span className="text-danger">*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form-control"
            id="username"
            placeholder="Username"
            name="username"
            {...register("username", signupOptions.username)}
          />
          <small className="text-danger">
            {errors?.username && errors.username.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label className="font--inter-semiBold text-black font-14 mb-2">
            Email <span className="text-danger">*</span>
          </label>
          <input
            autoComplete="off"
            type="email"
            className="form-control"
            id="email"
            placeholder="Email"
            name="email"
            {...register("email", signupOptions.email)}
          />
          <small className="text-danger">
            {errors?.email && errors.email.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label className="font--inter-semiBold text-black font-14 mb-2">
            Password <span className="text-danger">*</span>
          </label>
          <input
            autoComplete="off"
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            name="password"
            {...register("password", signupOptions.password)}
          />
          <small className="text-danger">
            {errors?.password && errors.password.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label className="font--inter-semiBold text-black font-14 mb-2">
            Confirm Password <span className="text-danger">*</span>
          </label>
          <input
            autoComplete="off"
            type="password"
            className="form-control"
            id="confirm_password"
            placeholder="Confirm Password"
            name="confirmPassword"
            {...register("confirmPassword", signupOptions.confirmPassword)}
          />
          <small className="text-danger">
            {errors?.confirmPassword && errors.confirmPassword.message}
          </small>
        </div>
        <label className="font--inter-semiBold text-black font-14 mb-2">
          Phone Number <span className="text-danger">*</span>
        </label>
        <div className="form-group mb-3 d-flex">
          <Controller
            control={control}
            render={({ field }) => (
              <PhoneNumberSelect
                ref={field.ref}
                phonePrefixes={phonePrefixes}
                selectedPhoneNumberPrefix={selectedPhoneNumberPrefix}
                setSelectedPhoneNumberPrefix={setSelectedPhoneNumberPrefix}
                {...field}
              />
            )}
            {...register("phoneNumberDataId", signupOptions.phoneNumberDataId)}
          />
          <input
            autoComplete="off"
            type="text"
            className="form-control"
            id="phone_number"
            placeholder="Phone Number"
            name="phoneNumber"
            {...register("phoneNumber", signupOptions.phoneNumber)}
          />
        </div>
        <small className="text-danger">
          {errors?.phoneNumber && errors.phoneNumber.message}
        </small>
        <Row className="m-0 my-3 justify-content-center">
          <p className="text-secondary text-center fw-700 p-0">
            By pressing “Purchase, you agree to our Terms and Conditions made
            available{" "}
            <Link className="text-black text-decoration-none" to="#">
              here.
            </Link>
          </p>
        </Row>
      </form>
    </BuyTokenSignUpLayout>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  phonePrefixes: props.utilsReducer.phoneNumberPrefixes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  utilsActions: bindActionCreators(utilsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyTokenSignUpForm);
