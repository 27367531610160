import { useState } from "react";
import Card from "./card";
import { allJobs } from "../../utils/jobs";
import Empty from "../commons/empty";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useEffect } from "react";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../Loader";

const Listing = ({ actions, jobs }) => {
  const [key, setKey] = useState("OPEN");
  const [isLoading, setIsLoading] = useState(false);

  const openEmptyData = {
    msg: "You have no open jobs. Add a new vacancy now.",
  };

  const expiredEmptyData = {
    msg: "Jobs that have expired will appear here.",
  };

  const closedEmptyData = {
    msg: "Jobs that have been closed will appear here.",
  };

  const changeTab = (status) => {
    setIsLoading(true);
    setKey(status);
    getAllJobByStatus(status);
  };

  const getAllJobByStatus = async (status) => {
    await actions.getAllJobs(status);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllJobByStatus("OPEN");
  }, []);

  const List = () => (
    <div className="jobs-main mt-4">
      {jobs?.map((job, i) => {
        if (!job?.id) return;

        return <Card key={job.id} job={job}></Card>;
      }) || []}
    </div>
  );
  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Row className="dashboard-title-row p-3 py-4 bg-white border-radius-15 mx-0 my-4">
      <Col xs={12} lg={12}>
        <Tabs
          id="jobs-tabs"
          activeKey={key}
          onSelect={(k) => changeTab(k)}
          className="mb-3 jobs-tabs filter-tabs"
          fill
        >
          <Tab eventKey="OPEN" title="Open Jobs">
            <div className="my-4">
              {jobs?.length > 0 ? <List /> : <Empty data={openEmptyData} />}
            </div>
          </Tab>
          <Tab eventKey="EXPIRED" title="Expired Jobs">
            <div className="my-4">
              {jobs?.length > 0 ? <List /> : <Empty data={expiredEmptyData} />}
            </div>
          </Tab>
          <Tab eventKey="CLOSED" title="Closed Jobs">
            <div className="my-4">
              {jobs?.length > 0 ? <List /> : <Empty data={closedEmptyData} />}
            </div>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

const mapStateToProps = (props) => ({
  jobs: props.jobReducer.jobListing,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
