import { useEffect } from "react";

export function UpdateActiveMenu(id) {
    useEffect(() => {
        let sidebar_links = document.querySelectorAll(".sidebar-menu-items a");
        for (var i = 0, len = sidebar_links.length; i < len; i++) {
            sidebar_links[i].classList.remove("active")
        }
        let elem = document.getElementById(id);
        if (!elem.classList.contains("active")) {
            elem.classList.add("active")
        }
    }, []);
}