import { Col } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import useProgression from "../../hooks/useProgression";
import * as utilsActions from "../../../../redux/actions/utilsAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useAxios } from "../../../../api/useAxios";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

const BasicInfo = ({ canProceed, setCanProceed, phonePrefixes }) => {
  const location = useLocation();
  const { post } = useAxios();
  const dispatch = useDispatch();
  const { handleProgressionData } = useProgression();

  const userNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [basicInfoData, setBasicInfoData] = useState({
    email: location?.state?.data?.email,
  });
  const [errorMessage, setErrorMessage] = useState();

  const handleResetError = () => {
    setErrorMessage();
    setCanProceed(true);
  };

  const handleValidateUsername = async () => {
    const username = userNameRef.current.value;

    if (!username || username?.length < 3) {
      setErrorMessage({
        ...errorMessage,
        username: "Username is required",
      });

      return;
    }

    const res = await post("user/username/validate", { username });

    if (!username) {
      setErrorMessage({
        ...errorMessage,
        username: "Username is required",
      });
    } else if (!res.isUsernameValid) {
      setErrorMessage({
        ...errorMessage,
        username: "Username already taken, please use a different one",
      });
    } else {
      const { username, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }
  };

  const handleValidateEmail = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email?.length < 3) {
      setErrorMessage({
        ...errorMessage,
        email: "Email is required",
      });

      return;
    }

    if (!password || password.length < 3 || !email || email.length < 3) return;

    const res = await post("user-auth/authenticate", {
      email,
      password,
    });

    const isValidEmail = /@/.test(email);

    if (!isValidEmail) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        email: "Invalid Email",
      }));
    } else if (!res?.errorMessage) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        email: res.errorMessage,
      }));
    } else {
      const { email, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }
  };

  const handleValidatePasswords = async () => {
    const pass = passwordRef.current.value;
    const confirmPass = confirmPasswordRef.current.value;

    if (!pass) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        password: "Password is required",
      }));

      return;
    } else if (!confirmPass) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        confirmPass: "Confirm Password is required",
      }));
    } else if (pass.length < 8) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        password: "Password must have at least 8 characters",
      }));
    } else if (confirmPass !== pass) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        confirmPass: "Your passwords do not match",
      }));
    } else {
      const { confirmPass, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }
  };

  useEffect(() => {
    const step2Data = handleProgressionData.get("step 2");
    !basicInfoData && setBasicInfoData(step2Data);

    basicInfoData &&
      handleProgressionData.set("step 2", {
        ...step2Data,
        ...basicInfoData,
        basicInfoData: basicInfoData,
      });
  }, [basicInfoData]);

  useEffect(() => {
    phonePrefixes?.length < 1 &&
      dispatch(utilsActions.getPhoneNumberPrefixes());
  }, [dispatch]);

  useEffect(() => {
    handleValidatePasswords();
  }, [passwordRef?.current?.value, confirmPasswordRef?.current?.value]);

  useEffect(() => {
    handleValidateEmail();
  }, [passwordRef?.current?.value]);

  useEffect(() => {
    handleValidateUsername();
  }, [userNameRef?.current?.value]);

  useEffect(() => {
    typeof errorMessage === "object" && Object.keys(errorMessage).length > 0
      ? setCanProceed(false)
      : setCanProceed(true);
  }, [errorMessage]);

  useEffect(() => {
    handleValidateEmail();
    handleValidatePasswords();
    handleValidateUsername();
  }, [
    passwordRef?.current?.value,
    confirmPasswordRef?.current?.value,
    emailRef?.current?.value,
    userNameRef?.current?.value,
  ]);

  return (
    <Col xs={12} className="p-5 justify-content-center">
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Username
        </label>
        <input
          ref={userNameRef}
          type="text"
          className="form-control"
          id="username"
          placeholder="Username *"
          name="username"
          value={basicInfoData?.username || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setBasicInfoData({ ...basicInfoData, username: value });
          }}
          onBlur={({ currentTarget: { value } }) =>
            handleValidateUsername(value)
          }
        />
        <div className="container-errorMessage text-danger --text-12 pt-2">
          {errorMessage?.username}
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Email
        </label>
        <input
          ref={emailRef}
          type="email"
          className="form-control"
          id="email"
          placeholder="Email *"
          name="email"
          value={basicInfoData?.email || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setBasicInfoData({ ...basicInfoData, email: value });
          }}
          onBlur={({ currentTarget: { value } }) => handleValidateEmail(value)}
        />
        <div className="container-errorMessage text-danger --text-12 pt-2">
          {errorMessage?.email}
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Password
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="Password *"
          name="password"
          defaultValue=""
          ref={passwordRef}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setBasicInfoData({ ...basicInfoData, password: value });
            handleValidatePasswords();
          }}
        />
        <div className="container-errorMessage text-danger --text-12 pt-2">
          {errorMessage?.password}
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Confirm Password
        </label>
        <input
          type="password"
          className="form-control"
          id="confirm_password"
          placeholder="Confirm Password *"
          name="confirmPassword"
          defaultValue=""
          ref={confirmPasswordRef}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setBasicInfoData({ ...basicInfoData, confirmPassword: value });
            handleValidatePasswords();
          }}
        />
        <div className="container-errorMessage text-danger --text-12 pt-2">
          {errorMessage?.confirmPass}
        </div>
      </div>
      <label className="text-black font-14 mb-1" htmlFor="form-control">
        Phone Number
      </label>
      <div className="form-group mb-3 d-flex bg-white">
        <select
          name="phoneNumberDataId"
          value={basicInfoData?.phoneNumberDataId || 1 || ""}
          onChange={({ currentTarget: { value } }) => {
            setBasicInfoData({ ...basicInfoData, phoneNumberDataId: value });
          }}
          style={{
            border: "none",
            outline: "none",
          }}
        >
          {phonePrefixes?.length > 0 &&
            phonePrefixes
              .sort((a, b) => a.callingCode - b.callingCode)
              .map((phonePrefix) => (
                <option key={phonePrefix.id} value={phonePrefix.id}>
                  {phonePrefix.callingCode}
                </option>
              ))}
        </select>
        <input
          type="text"
          className="form-control"
          id="phone_number"
          placeholder="Phone Number *"
          name="phoneNumber"
          value={basicInfoData?.phoneNumber || ""}
          onChange={({ currentTarget: { value } }) =>
            setBasicInfoData({ ...basicInfoData, phoneNumber: value })
          }
        />
      </div>
    </Col>
  );
};

const mapStateToProps = (props) => ({
  phonePrefixes: props.utilsReducer.phoneNumberPrefixes,
});

const mapDispatchToProps = (dispatch) => ({
  utilsActions: bindActionCreators(utilsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);
