import { useState, useEffect } from "react";
import { PURCHASE_TOKEN_SUCCESS } from "../../../constants/action-types";
import useToken from "./useToken";
import { useNavigate } from "react-router-dom";
import { successAlert } from "../../../constants/helper";

const useHandleStripe = ({
  actions,
  status,
  tokens,
  amountToken,
  tokenPrice,
}) => {
  const { handleGetPreBuyTokenDetails } = useToken({ actions, status, tokens });
  const [show, setShow] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showStripeResponse, setShowStripeResponse] = useState(false);
  const [stripeResponseMsg, setStripeResponseMsg] = useState("");
  const [preBuyTokenInfo, setPreBuyTokenInfo] = useState();

  const handleShowStripeResponse = () => setShowStripeResponse(true);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleCloseStripe = () => setShowStripe(false);
  const handleShowStripe = () => setShowStripe(true);

  const openStripe = async () => {
    const preBuyTokenInfo = await handleGetPreBuyTokenDetails(
      tokenPrice,
      amountToken
    );
    handleClose();
    handleShowStripe();

    return preBuyTokenInfo;
  };

  const handleCloseStripeResponse = () => {
    setShowStripeResponse(false);
    window.location.reload(false);
  };

  useEffect(() => {
    if (status === PURCHASE_TOKEN_SUCCESS) {
      setStripeResponseMsg(amountToken + " tokens purchased!");
      actions.emptyStatus();
      handleClose();
      handleCloseStripe();
      handleShowStripeResponse();

      successAlert(amountToken + " Diamond tokens purchased!");
      navigate("/dashboard", { replace: true });
    }
  }, [status]);

  return {
    show,
    setShow,
    openStripe,
    showStripe,
    handleCloseStripe,
    showStripeResponse,
    handleCloseStripeResponse,
    stripeResponseMsg,
    setStripeResponseMsg,
    handleShowStripeResponse,
  };
};

export default useHandleStripe;
