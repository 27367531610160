import { useState } from "react";
import { Link } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function JobTabs() {
  const [key, setKey] = useState("paid");
  return (
    <Row
      className={"dashboard-title-row p-3 py-4 bg-white border-radius-15 my-4"}
    >
      <Col xs={12} lg={12}>
        <Tabs
          id="jobs-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 jobs-tabs filter-tabs"
          fill
        >
          <Tab eventKey="paid" title="Paid job posting">
            <Row className="my-4">
              <Col xs={12} sm={6} className="hover-dark">
                <Link
                  className="text-decoration-none d-flex flex-column justify-content-center align-items-center py-4"
                  to="/jobs/create-a-new-job/full_time"
                >
                  <Row>
                    <p className="font--inter-semiBold font-20 mb-2 blue-text text-center">
                      Basic Token
                    </p>
                    <Col xs={12} lg={6} className="text-center">
                      <div>
                        <img
                          className="mb-2"
                          src="/assets/icons/tokens/token-basic-lg.png"
                          alt="Basic Token"
                          width={100}
                          height={100}
                        />
                      </div>
                      <span className="font--inter-semiBold --text-24 text-black">
                        RM 300
                      </span>
                    </Col>
                    <Col xs={12} lg={6}>
                      <div className="grey-bg d-flex flex-column h-100 px-0 py-4">
                        <span className="font--inter-semiBold text-black --text-16 px-3">
                          Basic Features:
                        </span>
                        <ul className="token-features dashed px-3">
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Purchase token on the app to post a full time,
                              contract or freelance role{" "}
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Amplification of job post & employer brand on our
                              social media channels (25k+ followers){" "}
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Job postings distributed to weekly EDM list (10k+
                              jobseekers)
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Service guarantee: Free job post extension for 30
                              days if there’s less than 20 applications
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              No expiration date for when you post your job
                              vacancies
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Col>

              <Col xs={12} sm={6} className="hover-dark">
                <Link
                  className="text-decoration-none d-flex flex-column justify-content-center align-items-center py-4"
                  to="/jobs/create-a-new-job/full_time?premium=true"
                >
                  <Row>
                    <p className="font--inter-semiBold font-20 mb-2 blue-text text-center">
                      Premium Token
                    </p>
                    <Col xs={12} lg={6} className="text-center">
                      <div>
                        <img
                          className="mb-2"
                          src="/assets/icons/tokens/token-premium-lg.png"
                          alt="Premium Token"
                          width={100}
                          height={100}
                        />
                      </div>
                      <span className="font--inter-semiBold --text-24 text-black">
                        RM 600
                      </span>
                    </Col>
                    <Col xs={12} lg={6}>
                      <div className="grey-bg d-flex flex-column h-100 px-0 py-4">
                        <span className="font--inter-semiBold text-black --text-16 px-3">
                          Premium Features:
                        </span>
                        <ul className="token-features dashed px-3">
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Includes everything in ‘Basic Job Token’
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Vetting and Shortlisting: Our experienced team
                              will vet and shortlist 5-7 resumes for the role
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Service valid for 30 days from activation
                            </span>
                          </li>
                          <li>
                            <span className="--text-14 text-808080">
                              {" "}
                              Rapid Results: Expect 1st batch of 5-7 qualified
                              candidates in 5-7 working days
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="free" title="Free job posting">
            <Row className="my-4">
              <Col xs={12} sm={12}>
                <Link
                  className="text-decoration-none"
                  to="/jobs/create-a-new-job/internship"
                >
                  <p className="font--inter-semiBold --text-24 mb-2 blue-text text-center">
                    Internship position
                  </p>
                  <div className="grey-bg px-2 py-4 d-flex flex-column">
                    <span className="mx-3 font--inter-semiBold text-black --text-24">
                      Basic Features:
                    </span>
                    <span className="mx-3 --text-16 text-808080">
                      - Free Job Posting
                    </span>
                  </div>
                </Link>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
}
