import Header from "../components/transaction/header";
import Listing from "../components/transaction/listing";
import Container from 'react-bootstrap/Container';
import { UpdateActiveMenu } from '../utils/update_active';

export default function TransactionHistory(props) {

    UpdateActiveMenu("sl-billing");

    return (
        <>
            <div className="content">
                <Container fluid>
                    <Header></Header>
                    <Listing></Listing>
                </Container>
            </div>
        </>
    );
}