import { Modal } from "react-bootstrap";
import Stripe from "../../components/billing/stripe";
import { useEffect, useState } from "react";
import { PURCHASE_TOKEN_SUCCESS } from "../../constants/action-types";

const StripeModals = ({
  amountToken,
  status,
  tokenPrice,
  actions,
  show,
  setShow,
  openStripe,
  showStripe,
  handleCloseStripe,
  showStripeResponse,
  handleCloseStripeResponse,
  stripeResponseMsg,
  setStripeResponseMsg,
  handleShowStripeResponse,
}) => {
  const [preBuyTokenInfo, setPreBuyTokenInfo] = useState();

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (status === PURCHASE_TOKEN_SUCCESS) {
      setStripeResponseMsg(amountToken + " tokens purchased!");
      actions.emptyStatus();
      handleClose();
      handleCloseStripe();
      handleShowStripeResponse();
    }
  }, [status]);

  const handleOpenStripe = async () => {
    const prebuyInfo = await openStripe();
    setPreBuyTokenInfo(prebuyInfo.response);
  };

  return (
    <>
      <Modal
        className={"status-modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            Purchase Tokens
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          {amountToken === 0 ? (
            <>
              <p className="font-16 blue-text text-center mb-4">
                {amountToken} tokens selected, please select token to continue.
              </p>
              <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
                <button
                  onClick={handleClose}
                  className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="font-16 blue-text text-center mb-4">
                Confirm purchase {amountToken} tokens?
              </p>
              <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
                <button
                  onClick={handleClose}
                  className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleOpenStripe()}
                  className="w-100 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className={"status-modal"}
        show={showStripe}
        onHide={handleCloseStripe}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            Purchase Tokens
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          {preBuyTokenInfo && (
            <Stripe
              tokenId={2}
              tokenCount={amountToken}
              totalPrice={tokenPrice * amountToken || 0}
              preBuyTokenInfo={preBuyTokenInfo}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className={"status-modal"}
        show={showStripeResponse}
        onHide={handleCloseStripeResponse}
        centered
      >
        <Modal.Header className={"border-0 p-3"}>
          <Modal.Title className={"blue-text font-32 font--inter-bold mx-auto"}>
            {stripeResponseMsg}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row align-items-start justify-content-center p-0 header-buttons mb-2 col-12">
            <button
              onClick={handleCloseStripeResponse}
              className="w-100 me-3 text-decoration-none bg-transparent px-3 py-2 blue-text rounded font--inter-semiBold blue-border"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StripeModals;
