import { Link } from "react-router-dom";

export default function ActionLink(props) {

    return (
        <Link to={props.to} className="to-link ds-link d-flex flex-row align-items-center justify-content-between grey-bg p-4 my-4 rounded text-decoration-none">
            <div className='d-flex flex-row align-items-start justify-content-between'>
                <img src={props.icon} alt="Action" className='me-3' />
                <div>
                    <p className="mb-1 blue-text font--inter-semiBold font-20">{props.title}</p>
                    <p className="m-0 text-secondary font-16">{props.text}</p>
                </div>
            </div>
            <img src="/assets/icons/right.png" alt="Right" />
        </Link>
    );
}