import { useEffect, useState } from "react";
import { GetFileData, errorAlert } from "../../../constants/helper";

// const checkImageHeightSize = (fileObject, maxHeight) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.onload = function () {
//       const height = img.height;
//       if (height <= maxHeight) {
//         resolve(true);
//       } else {
//         errorAlert(
//           "Uploaded Company Banner Image Exceeded Allowed Height",
//           10000
//         );
//         resolve(false);
//       }
//     };
//     img.onerror = function () {
//       reject(new Error("Failed to load image."));
//     };
//     img.src = URL.createObjectURL(fileObject);
//   });
// };

const useProfile = (user) => {
  const [profile, setProfile] = useState();
  const [profileImage, setProfileThumbnail] = useState("");
  const [headerImage, setHeaderThumbnail] = useState("");
  const [companyImages, setCompanyImages] = useState([]);
  const [thumbnailImages, setThumbnailImages] = useState([]);

  const whiteListedImageTypes = ["jpeg", "jpg", "png", "ico"];

  const handleImageTypeCheck = (fileType) => {
    if (!whiteListedImageTypes.includes(fileType)) {
      errorAlert("Image type not allowed");
      return false;
    } else return true;
  };

  const onProfileImageChange = async (event) => {
    const image = event.target.files[0];
    const readFile = await GetFileData(image);

    const fileType = readFile.url.split(";")[0].split("/")[1];
    let isValidImage;

    isValidImage = handleImageTypeCheck(fileType);

    isValidImage &&
      setProfileThumbnail({
        filePreview: readFile.url,
        fileData: readFile.url.split(",")[1],
        fileName: readFile.filename,
      });
  };

  const onHeaderImageChange = async (event, setShowHeaderCropModal) => {
    const image = event.target.files[0];
    const readFile = await GetFileData(image);

    const fileType = readFile.url.split(";")[0].split("/")[1];
    let isValidImage;

    isValidImage = handleImageTypeCheck(fileType);
    // isValidImage = await checkImageHeightSize(image, 700);

    if (isValidImage) {
      setHeaderThumbnail({
        fileCroppedPreview: readFile.url,
        filePreview: readFile.url,
        fileData: readFile.url.split(",")[1],
        fileName: readFile.filename,
      });

      setShowHeaderCropModal(true);
    }
  };

  const onAddCompanyImages = async (event) => {
    const images = event.target.files;
    const newImageUpload = [...companyImages];
    newImageUpload.push(images);
    setCompanyImages(newImageUpload);
    let canUpload = false;

    let imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const readFile = await GetFileData(images[i]);

      const fileType = readFile.url.split(";")[0].split("/")[1];
      const isValidImage = handleImageTypeCheck(fileType);

      if (isValidImage) {
        imageUrls.push({
          filePreview: readFile.url,
          fileData: readFile.url.split(",")[1],
          fileName: readFile.filename,
        });

        canUpload = true;
      } else canUpload = false;
    }

    canUpload && setThumbnailImages(imageUrls);
  };

  useEffect(() => {
    user && !profile?.username && setProfile(user);
  }, [user]);

  return {
    profile,
    setProfile,
    profileImage,
    onProfileImageChange,
    setProfileThumbnail,
    headerImage,
    onHeaderImageChange,
    setHeaderThumbnail,
    companyImages,
    onAddCompanyImages,
    setCompanyImages,
    thumbnailImages,
    setThumbnailImages,
  };
};
export default useProfile;
