import { Col } from "react-bootstrap";
import { useEffect } from "react";

export const ProfilePhoto = ({
  // handleProgressionData,
  profileImage,
  onProfileImageChange,
}) => {
  useEffect(() => {
    // const step1Data = handleProgressionData.get("step 1");
    const step1Data = {};

    // profileImage &&
    //   handleProgressionData.set("step 1", {
    //     ...step1Data,
    //     profileImage: profileImage,
    //   });
  }, [profileImage]);

  return (
    <Col xs={12} className="p-5 justify-content-center">
      <label
        className="text-black font-14 mb-1 text-center w-100"
        htmlFor="form-control"
      >
        Profile Photo
      </label>
      <input
        onChange={onProfileImageChange}
        type="file"
        className="hide"
        accept="image/*"
        id="profile-image"
      />
      <label
        id="profile-image-label"
        htmlFor="profile-image"
        className="w-100 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border border-3px-dashed cursor-pointer"
        style={{
          height: 174,
          background: `url(${
            // handleProgressionData.get("step 1")?.profileImage?.filePreview ||
            profileImage.filePreview
          })`,
        }}
      >
        {!profileImage && (
          // && !handleProgressionData.get("step 1")?.profileImage?.filePreview
          <>
            <img
              src="/assets/icons/camera.png"
              alt="camera"
              className="me-2"
              id="profile-image-src"
            />

            <span>Company Logo</span>
          </>
        )}
      </label>
      <p className="text-center">Maximum dimension: 250x250</p>
    </Col>
  );
};

export const BannerPhoto = ({
  // handleProgressionData,
  headerImage,
  onHeaderImageChange,
}) => {
  useEffect(() => {
    // const step1Data = handleProgressionData.get("step 1");
    const step1Data = {};

    // headerImage &&
    //   handleProgressionData.set("step 1", {
    //     ...step1Data,
    //     headerImage: headerImage,
    //   });
  }, [headerImage]);

  return (
    <Col xs={12} className="p-5 justify-content-center">
      <div className="form-group mb-3">
        <label
          className="text-black font-14 mb-1 text-center w-100"
          htmlFor="form-control"
        >
          Banner Photo
        </label>
        <input
          onChange={onHeaderImageChange}
          type="file"
          className="hide"
          accept="image/*"
          id="header-image"
        />
        <label
          id="header-image-label"
          htmlFor="header-image"
          className="w-100 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border border-3px-dashed cursor-pointer"
          style={{
            height: 174,
            background: `url(${
              // handleProgressionData.get("step 1")?.headerImage?.filePreview ||
              headerImage.filePreview
            })`,
          }}
        >
          {!headerImage && (
            // !handleProgressionData.get("step 1")?.headerImage?.filePreview &&
            <>
              <img
                src="/assets/icons/camera.png"
                alt="camera"
                className="me-2"
              />
              <span>Company Banner Image</span>
            </>
          )}
        </label>
        <p className="text-center">Maximum dimension: 700x400</p>
      </div>
    </Col>
  );
};

const AccountPhotos = (props) => {
  return (
    <>
      <ProfilePhoto {...props} />
      <BannerPhoto {...props} />
    </>
  );
};

export default AccountPhotos;
