import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/dashboard/header";

import TitleBar from "../components/headers/title_bar";

import TodaysOverview from "../components/dashboard/todays_overview";
import Listing from "../components/dashboard/listing";
// import WeeklyMeetingOverview from "../components/dashboard/meetings";
import { UpdateActiveMenu } from "../utils/update_active";
import { useEffect } from "react";

const Dashboard = () => {
    UpdateActiveMenu("sl-dashboard");

    const jobTitleData = {
    subNotBold: "",
    subBold: "Job Listings",
    description:
      "This is your dashboard. Your all in one platform for job management.",
    buttons: false,
  };

  return (
    <div className="content">
      <Container fluid>
        <Header />
        <Row className="mt-4">
          <Col xs={12} md={12}>
            <TodaysOverview />
          </Col>
          {/* <Col xs={12} md={6}>
              <WeeklyMeetingOverview />
            </Col> */}
        </Row>
        <Row className="my-4">
          <Col className="mt-4" xs={12}>
            <TitleBar data={jobTitleData} buttons={false} />
            <Listing />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
