import React, { useState } from "react";
import {
  useStripe,
  useElements,
  // PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import * as Actions from "../../redux/actions/jobAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const CheckoutForm = ({
  actions,
  clientSecret,
  tokenId,
  tokenCount,
  tokenPrice,
  discountId,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    setPaymentStatus("loading");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
      setPaymentStatus("idle");
    } else {
      const { error: confirmationError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });

      if (confirmationError) {
        setErrorMessage(confirmationError.message);
        setPaymentStatus("idle");
      } else {
        const data = {
          tokenId,
          tokensBought: tokenCount,
          amount: tokenPrice,
          transactionId: paymentIntent.id,
        };

        if (discountId) data.discountId = discountId;

        actions.purchaseToken(data);
        setPaymentStatus(paymentIntent.status);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{}} />
      {/* <PaymentElement /> */}
      <button
        type="submit"
        disabled={paymentStatus === "loading"}
        className={`w-100 text-decoration-none ${
          paymentStatus === "succeeded"
            ? "bg-success border-0"
            : "blue-bg blue-border"
        } px-3 py-2 my-3 text-white rounded font--inter-semiBold`}
      >
        Pay
      </button>
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </form>
  );
};

const mapStateToProps = (props, parentprops) => ({
  tokenCount: parentprops.tokenCount,
  tokenPrice: parentprops.tokenPrice,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
