import {
  GET_ALL_CREATIVES,
  GET_TRANSACTIONS_HISTORY,
  CREATIVES_PAGINATION,
} from "../../constants/action-types";
import { request } from "../../services/http-service";

export const getAllCreatives = (options) => {
  if (!options) {
    options = {
      pageIndex: 0,
      pageSize: 15,
    };
  }

  return (dispatch) => {
    return request("cced/creatives/all", "POST", options, true, dispatch).then(
      (res) => {
        if (res.status === "ok") {
          dispatch({
            type: GET_ALL_CREATIVES,
            data: res["creatives_profiles"],
          });

          dispatch({
            type: CREATIVES_PAGINATION,
            data: {
              ...options,
              totalCount: res.totalCount,
            },
          });
        } else {
        }
      }
    );
  };
};

export const getTransactionHistory = (data) => {
  return (dispatch) => {
    return request(
      "cced/utility/transactions/history",
      "POST",
      data,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: GET_TRANSACTIONS_HISTORY, data: res["transactions"] });
      }
    });
  };
};
