import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  EMPTY_STATUS,
  GET_USER_TOKENS,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
} from "../../constants/action-types";
import { request } from "../../services/http-service";
import { successAlert, errorAlert } from "../../constants/helper";

export const getCurrentUser = () => {
  return (dispatch) => {
    try {
      return request("cced/user/details/logged-in", "GET", "", true).then(
        (res) => {
          if (res && res.status === "ok") {
            dispatch({ type: AUTHENTICATE_USER_SUCCESS, data: res });
          } else {
            dispatch({
              type: AUTHENTICATE_USER_FAILURE,
              payload: "User created Failure",
            });
            localStorage.removeItem("token");
          }

          return res;
        }
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  };
};

export const loginUser = (loginData, hideAlert) => {
  return async (dispatch) => {
    try {
      const loginResponse = await request(
        "user-auth/authenticate",
        "post",
        loginData,
        false
      );
      if (loginResponse && loginResponse.token) {
        localStorage.setItem("token", loginResponse.token);
        dispatch({ type: LOGIN_SUCCESS });
        dispatch(getCurrentUser());
        !hideAlert && successAlert("User login Successfully");
        return loginResponse;
      } else {
        dispatch({ type: LOGIN_FAILURE });
        errorAlert(loginResponse.errorMessage);
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILURE, payload: error });
    }
  };
};

export const signup = (signupData, hideAlert) => {
  return (dispatch) => {
    return request("user/signup", "POST", signupData, true, dispatch).then(
      (res) => {
        if (res.status === "ok") {
          dispatch({ type: SIGNUP_USER_SUCCESS, data: res["user"] });
          !hideAlert && successAlert("Account created");
          return res.status;
        } else {
          dispatch({
            type: SIGNUP_USER_FAILURE,
            payload: "User signup Failure",
          });
          localStorage.removeItem("token2");
          !hideAlert &&
            errorAlert(res?.errorMessage || "Failed to create account");

          return res.errorMessage;
        }
      }
    );
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    localStorage.removeItem("token");
    successAlert("User logout Successfully");
  };
};

export const emptyStatus = () => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_STATUS,
    });
  };
};

export const getUserTokens = () => async (dispatch) => {
  try {
    const res = await request("cced/jobs/token/count", "GET", "", true);
    if (res && res.status === "ok") {
      dispatch({ type: GET_USER_TOKENS, data: res.tokens });
    }
  } catch (error) {
    console.error(error);
  }
};

export const editProfile = (profileData) => {
  return async (dispatch) => {
    try {
      const editProfileResponse = await request(
        "cced/user/details/edit",
        "post",
        profileData,
        true
      );

      if (profileData.profileImageUpload) {
        await request(
          "uploads/upload/image/profile",
          "post",
          profileData.profileImageUpload,
          true
        );
      }

      if (profileData.coverImageUpload) {
        await request(
          "uploads/upload/image/cover",
          "post",
          profileData.coverImageUpload,
          true
        );
      }

      // if (profileData.galleryImagesUrls) {
      //   await request(
      //     "uploads/upload/image/gallery",
      //     "post",
      //     profileData.galleryImagesUrls,
      //     true
      //   );
      // }

      if (editProfileResponse && editProfileResponse.status === "ok") {
        dispatch({ type: PROFILE_UPDATE_SUCCESS });
        successAlert("Updated Profile Successfully");
        return editProfileResponse.status;
      } else {
        dispatch({ type: PROFILE_UPDATE_FAILURE });
        errorAlert(editProfileResponse.errorMessage);
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: PROFILE_UPDATE_FAILURE, payload: error });
    }
  };
};

export const loginUserSocial = (loginData, hideAlert) => {
  return async (dispatch) => {
    try {
      if (loginData?.token) {
        const loginResponse = await request(
          `user-auth/authenticate/${loginData.providerId}`,
          "post",
          loginData,
          false,
          dispatch
        );

        if (loginResponse.status === "ok") {
          localStorage.setItem("token", loginResponse.token);
          dispatch({ type: LOGIN_SUCCESS });
          const getUserData = await dispatch(getCurrentUser());

          if (getUserData.errorMessage) return getUserData;

          !hideAlert && successAlert("User login Successfully");

          return loginResponse.status;
        } else {
          return loginResponse;
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILURE, payload: error });
    }
  };
};
