import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { useEffect } from "react";
import * as Actions from "../../redux/actions/userAction";
import { bindActionCreators } from "redux";

const Token = ({ actions, tokens }) => {
  useEffect(() => {
    actions.getUserTokens();
  }, []);

  return (
    <>
      <Row className={"p-3 py-4 bg-white border-radius-15 mx-0 my-4"}>
        <Col xs={12} md={6} className={"header-text ps-0 d-flex"}>
          <img
            src="/assets/icons/tokens/token-basic-sm.png"
            className="me-3"
            alt="Basic Token"
            width={100}
            height={100}
          />
          <h4 className="font--inter-regular font-24 mb-0">
            <span className="font--inter-bold">Basic token balance: </span>
            <br />
            <span className={"font--inter-bold font-36"}>
              {tokens?.balance?.gold ?? ""}
            </span>{" "}
            tokens
          </h4>
        </Col>

        <Col xs={12} md={6} className={"header-text ps-0 d-flex --md-mt-30"}>
          <img
            src="/assets/icons/tokens/token-premium-sm.png"
            className="me-3"
            alt="Premium Token"
            width={100}
            height={100}
          />
          <h4 className="font--inter-regular font-24 mb-0">
            <span className="font--inter-bold">Premium token balance: </span>
            <br />
            <span className={"font--inter-bold font-36"}>
              {tokens?.balance?.diamond ?? ""}
            </span>{" "}
            tokens
          </h4>
        </Col>

        <hr className="my-4" />
        <Link
          to="/billing/transaction-history"
          className="text-black font-20 font--inter-medium text-decoration-none d-flex align-items-center justify-content-between"
        >
          <span>Transaction History</span>{" "}
          <img src="/assets/icons/right.png" alt="right" />
        </Link>
      </Row>
    </>
  );
};

const mapStateToProps = (props) => ({
  tokens: props.userReducer.tokens,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Token);
