import { Link, useLocation, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import { useAxios } from "../../api/useAxios";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userAction";
import { errorAlert } from "../../constants/helper";
import { Button, Modal } from "react-bootstrap";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import BootstrapModal from "../BootstrapModal";

const TitleBar = ({ data, userActions, user }) => {
  const navigate = useNavigate();
  const { post } = useAxios();
  const location = useLocation();

  const { tokens } = useSelector(({ userReducer }) => userReducer);
  const [show, setShow] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showContinueCreateJob, setShowContinueCreateJob] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteAccount = async () => {
    const res = await post("user/account/delete");

    if (res.status === "ok") {
      errorAlert("Account Deactivated");
      userActions.logoutAction();
    } else errorAlert(res.errorMessage);
  };

  return (
    <>
      <BootstrapModal
        title="Choose your job type"
        children={
          <div className="d-flex flex-column">
            <span>Select your job posting type either Paid Job Posting</span>
            <span>or Free Job Posting t the button below.</span>
          </div>
        }
        footer={true}
        footerBtnLeftText="Paid Job Posting"
        footerBtnLeftVariant="dark"
        footerBtnLeftCallback={() =>
          navigate("/jobs/create-a-new-job", { replace: true })
        }
        footerBtnRightText="Free Job Posting"
        footerBtnRightVariant="dark"
        footerBtnRightCallback={() =>
          navigate("/jobs/create-a-new-job/internship", { replace: true })
        }
        show={showContinueCreateJob}
        onHide={() => setShowContinueCreateJob(false)}
        toggleShow={setShowContinueCreateJob}
        // confirmCallback={() =>
        //   navigate("/jobs/create-a-new-job", { replace: true })
        // }
      />
      <Modal
        className="reset-password-modal"
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
        centered
      >
        <Modal.Header className="border-0 p-3" closeButton>
          <Modal.Title className="text-white font-24 ">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <ResetPasswordForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            resetMode={"loggedOn"}
            setShowResetPasswordModal={setShowResetPasswordModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="status-modal"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header className="border-0 p-3">
          <Modal.Title className={"blue-text font-24 font--inter-bold mx-auto"}>
            Confirm Delete Account ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col xs={6}>
              <button
                onClick={() => setShow(false)}
                className="w-100 text-decoration-none blue-bg px-3 py-3 text-white rounded font--inter-semiBold border-0"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6}>
              <button
                onClick={() => handleDeleteAccount()}
                className="w-100 text-decoration-none bg-danger px-3 py-3 text-white rounded font--inter-semiBold border-0"
              >
                Confirm
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Row className={"dashboard-title-row"}>
        <Col xs={12} lg={7} className={"header-text"}>
          <h4 className="font--inter-regular font-40 mb-3">
            {data.subNotBold}{" "}
            <span className={"font--inter-bold"}>{data.subBold}</span>
          </h4>
          <div className="text-secondary font-16 mb-2">{data.description}</div>
        </Col>
        {data.buttons !== false ? (
          <Col
            xs={12}
            lg={5}
            className={
              "d-flex flex-row align-items-start justify-content-end p-0 header-buttons mb-2"
            }
          >
            {/* {data.button1 ? (
              data.button1 === "Delete Account" ? (
                <Button
                  className="delete-account-btn text-decoration-none bg-transparent px-3 py-2 red-text rounded font--inter-semiBold red-border"
                  onClick={() => setShow(true)}
                >
                  Delete Account
                </Button>
              ) : (
                ""
              )
            ) : (
              ""
            )} */}
            {location?.pathname === "/account" ? (
              <div className="d-flex align-items-center ">
                <Button
                  className="mx-3 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                  onClick={() => setShowResetPasswordModal(true)}
                >
                  Change Password
                </Button>
                <Link
                  className="mx-3 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                  to={`${process.env.REACT_APP_CCWL_REDIRECT_URI}/employer/${user.id}`}
                  target="_blank"
                >
                  View Profile
                </Link>
              </div>
            ) : data.button2 ? (
              data.button2 === "Save" ? (
                <Link
                  className="save-account-btn text-decoration-none bg-secondary px-3 py-2 text-white rounded font--inter-semiBold border-secondary"
                  to="/jobs/create-a-new-job"
                >
                  Save
                </Link>
              ) : (
                ""
              )
            ) : (
              <button
                className="mx-3 text-decoration-none blue-bg px-3 py-2 text-white rounded font--inter-semiBold blue-border"
                onClick={() => setShowContinueCreateJob(true)}
              >
                Create a new job
              </button>
            )}
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (props) => ({
  user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
