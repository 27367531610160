import Carousel from "react-bootstrap/Carousel";
import "./assets/style.css";

const BootstrapCarousel = ({ items }) => {
  return (
    <Carousel slide>
      {items &&
        items.map((item, i) => (
          <Carousel.Item key={i}>
            <img
              className="carousel-item d-block w-100"
              src={item.imgSrc}
              alt={item.imgAlt}
            />
            {item.captionTitle && (
              <Carousel.Caption className="carousel-caption">
                <h3>{item.captionTitle}</h3>
                <p>{item.caption}</p>
              </Carousel.Caption>
            )}
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default BootstrapCarousel;
