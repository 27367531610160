import { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import { useAxios } from "../api/useAxios";
import LoginLayout from "../components/layouts/LoginLayout";
import { errorAlert, successAlert } from "../constants/helper";

const ForgotPassword = () => {
  const { post } = useAxios();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const emailRef = useRef(null);

  const handleResetPassword = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const isValidEmail = /@/.test(emailRef.current.value);

    if (!isValidEmail) return errorAlert("Invalid email entered");

    const res = await post("user-auth/password/forget/generate/token", {
      email: emailRef.current.value,
    });

    res?.status === "ok"
      ? successAlert("Password reset instructions sent to your email")
      : errorAlert(res?.errorMessage);

    setIsLoading(false);
  };

  return (
    <>
      <LoginLayout>
        <form className={"ls-form login-form w-100"}>
          <h3 className={"text-center mb-5 fw-700"}>Forgot Password</h3>
          <div className="form-group mb-5">
            <input
              ref={emailRef}
              type="text"
              className="form-control"
              id="email"
              placeholder="Email *"
            />
          </div>
          <Row className="m-0 mb-1 justify-content-center">
            <button
              disabled={isLoading}
              className="py-2 px-5 w-auto my-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700"
              type="submit"
              onClick={(e) => handleResetPassword(e)}
            >
              SEND ME AN EMAIL
            </button>
          </Row>
          <Row className="m-0 mb-3 justify-content-center">
            <p className="text-secondary text-center fw-700">
              We will send you an email for reset password.
            </p>
          </Row>
        </form>
      </LoginLayout>
    </>
  );
};

export default ForgotPassword;
