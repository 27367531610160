import Spinner from "react-bootstrap/Spinner";
import "./assets/style.scss";

const Loader = ({ isLoading }) => {
  if (!isLoading) return <></>;

  return (
    <div id="loaderBG" className="loading">
      <div className="loader">
        <Spinner animation="border" variant="dark" />
      </div>
    </div>
  );
};

export default Loader;
