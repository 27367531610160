import React, { useEffect, useState } from "react";
import * as Actions from "../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { allJobs } from "../utils/jobs";
import TitleBar from "../components/job/title_bar";
import ApplicationStatusBar from "../components/job/application_status_bar";
import Listing from "../components/job/listing";
import { UpdateActiveMenu } from "../utils/update_active";

const Job = ({ actions, isLoggedIn, selectedJob, route }) => {
  UpdateActiveMenu("sl-jobs");

  const params = useParams();
  const job_id = parseInt(params.job_id);
  const listing = allJobs();
  const [selectedJobData, setSelectedJobData] = useState({});

  useEffect(() => {
    if (selectedJob) {
      setSelectedJobData(selectedJob);
    }
  }, [selectedJob]);

  useEffect(() => {
    actions.getSelectedJobById(job_id);
  }, []);

  const searchInputField = (search) => {
    //actions.getSelectedJobById(job_id,search);
  };
  return (
    <>
      <div className="content">
        <Container fluid>
          <Link
            to="/jobs"
            className="mb-4 font-18 blue-text text-decoration-none font--inter-semiBold d-flex align-items-center"
          >
            <img src="/assets/icons/left.png" alt="left" className="me-2" />{" "}
            Back to Jobs
          </Link>
          <TitleBar jobId={job_id} job={selectedJobData}></TitleBar>
          <ApplicationStatusBar
            job={selectedJobData}
            jobID={job_id}
          ></ApplicationStatusBar>
          <Listing
            searchData={searchInputField}
            job={selectedJobData}
            jobId={job_id}
          ></Listing>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  selectedJob: props.jobReducer.selectedJob,
  route: parentProps.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Job);
