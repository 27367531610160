import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ApplicationStatusBar(props) {
  const job = props.job;
  const { selectedJob } = useSelector(({ jobReducer }) => jobReducer);

  let d = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lastUpdate =
    d.getDate() +
    " " +
    monthNames[d.getMonth()] +
    ", " +
    d.getFullYear() +
    ", " +
    d.getHours() +
    ":" +
    d.getMinutes();

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Job Status
    </Tooltip>
  );

  return (
    <>
      <div
        className={
          "application-statuses-row blue-bg p-4 my-4 mx-0 border-radius-15"
        }
      >
        <Row>
          <Col
            xs={3}
            className="d-flex align-items-center text-white font-16 font--inter-semiBold"
          >
            <div
              className={
                "status-badge me-3 " +
                (job?.jobStatus === "LIVE" || job?.jobStatus === "OPEN"
                  ? "green-bg"
                  : job?.jobStatus === "Paused"
                  ? "yellow-bg"
                  : "red-bg")
              }
            ></div>
            {job?.jobStatus?.charAt(0).toUpperCase() +
              job?.jobStatus?.slice(1)?.toLowerCase()}
          </Col>
          <Col xs={6} className="text-center">
            <p className="font-24 font--inter-semiBold text-white m-0 line-height-normal d-flex flex-wrap align-items-center justify-content-center">
              Application Statuses
              <span className="font-14 font--inter-light">
                <img
                  className="ps-3 pe-2"
                  src="/assets/icons/clock-white.png"
                  alt="Clock"
                />
                {lastUpdate}
              </span>
            </p>
          </Col>
          <Col xs={3} className="text-end">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Button className="p-1 border-0 bg-transparent">i</Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={1}></Col>
          <Col xs={2}>
            <p className="text-center mb-2 font-24 font--inter-bold text-white">
              {selectedJob?.statuses?.PENDING ?? 0}
            </p>
            <p className="text-center mb-0 font-16 font--inter-semiBold text-white">
              Pending Review
            </p>
          </Col>
          <Col xs={2}>
            <p className="text-center mb-2 font-24 font--inter-bold text-white">
              {selectedJob?.statuses?.SHORTLISTED ?? 0}
            </p>
            <p className="text-center mb-0 font-16 font--inter-semiBold text-white">
              Shortlisted
            </p>
          </Col>
          <Col xs={2}>
            <p className="text-center mb-2 font-24 font--inter-bold text-white">
              {selectedJob?.statuses?.INTERVIEWED ?? 0}
            </p>
            <p className="text-center mb-0 font-16 font--inter-semiBold text-white">
              Interview Stage
            </p>
          </Col>
          <Col xs={2}>
            <p className="text-center mb-2 font-24 font--inter-bold text-white">
              {selectedJob?.statuses?.OFFERED ?? 0}
            </p>
            <p className="text-center mb-0 font-16 font--inter-semiBold text-white">
              Offered
            </p>
          </Col>
          <Col xs={2}>
            <p className="text-center mb-2 font-24 font--inter-bold text-white">
              {selectedJob?.statuses?.UNSUCCESSFUL ?? 0}
            </p>
            <p className="text-center mb-0 font-16 font--inter-semiBold text-white">
              Unsuccessful
            </p>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </div>
    </>
  );
}
