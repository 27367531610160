import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import ActionLink from "./action_link";
import * as Actions from "../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const TodaysOverview = ({ actions, actionsRequired }) => {
  const [open, setOpen] = useState(false);
  const [actionsData, setActionsData] = useState([]);
  useEffect(() => {
    actions.getActionsRequired();
  }, []);
  useEffect(() => {
    setActionsData(actionsRequired);
  }, [actionsRequired]);
  return (
    <>
      <Row className={"dashboard-todays-overview-row mb-4"}>
        <Col className="w-100">
          <div className="collapsible-div bg-white rounded p-4">
            <Button
              onClick={() => setOpen(!open)}
              aria-controls="to-collapsable"
              aria-expanded={open}
              className="collapse-btn bg-transparent border-0 text-black w-100 font-20 m-0 d-flex align-items-center justify-content-between p-0"
            >
              <div>
                <span className={"font--inter-bold"}>Actions Required</span>
              </div>
              <img
                src={
                  open ? "/assets/icons/top.png" : "/assets/icons/bottom.png"
                }
                alt="Arrow"
              />
            </Button>
            <Collapse in={open}>
              <div id="to-collapsable">
                {actionsRequired.map((action, i) => (
                  <ActionLink
                    key={i}
                    to={action.clickTo}
                    icon={action.imageUrl}
                    type={action.title}
                    title="Post a job"
                    text={action.details}
                  />
                ))}
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  //props: parentProps,
  actionsRequired: props.dashboardReducer.actionsRequired,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TodaysOverview);
