import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TitleBar from '../headers/title_bar';
import React, { useEffect } from "react";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const Header = ({ actions, isLogged, user, status }) => {
    const headerData = {
        "subNotBold": "",
        "subBold": "Job Listings",
        "description": "This is your dashboard. Your all in one platform for job management.",
    };

    return (
        <>
            <TitleBar user={user} data={headerData}></TitleBar>
        </>
    );
}

const mapStateToProps = (props) => ({
    isLogged: props.userReducer.isLoggedIn,
    user: props.userReducer.user,
    status: props.userReducer.status
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);