import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BuyTokenSignUpLayout = (props) => {
  return (
    <Container fluid className="p-0">
      <Row className="h-100 overflow-auto login-layout-row justify-content-center align-items-center">
        <Col>
          <div className="bg-white px-4 py-5 border-radius-25 w-100 d-flex align-items-center">
            {props.children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BuyTokenSignUpLayout;
