// import Carousel from 'react-bootstrap/Carousel';
import React from "react";
import Carousel from "react-grid-carousel";

export default function UserProjects({ data }) {
  const projects = data?.creativeProfile?.creativesPortfolios || [];
  const links = data?.creativeProfile?.userLinks || null;

  return (
    <>
      <p className="font-24 font--inter-semiBold text-black mb-3">
        {data?.creativeProfile?.name || "User"}'s Projects
      </p>

      <div className="user-carousel project-slider mb-5">
        <Carousel cols={3} rows={1} gap={30} loop>
          {projects?.length > 0 &&
            projects.map((project) => {
              return (
                <Carousel.Item>
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_CCWL_REDIRECT_URI}/project/${project.id}`}
                  >
                    <img
                      className="d-block w-100"
                      src={project?.imageUrl || "/assets/images/profile.png"}
                      alt={project?.title}
                    />
                  </a>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>

      {links &&
        links.map((link) => (
          <p className="font-16 font--inter-regular text-black mb-2">
            <span
              className="d-inline-block"
              style={{ width: "30px", textAlign: "center" }}
            >
              <img
                src={`/assets/icons/${link.userLinkType.toLowerCase()}.png`}
                alt="links"
                className="me-2"
                width={link.userLinkType === "FACEBOOK" ? 12 : 20}
                height={20}
              />
            </span>
            <a
              className="font--inter-semiBold"
              href={link.value}
              target="_blank"
              rel="noreferrer"
            >
              {link.userLinkType.charAt(0).toUpperCase() +
                link.userLinkType.slice(1).toLowerCase()}
            </a>
          </p>
        ))}
      {/* <p className="font-16 font--inter-regular text-black mb-2">
        <span
          className="d-inline-block"
          style={{ width: "30px", textAlign: "center" }}
        >
          <img src="/assets/icons/f.png" alt="f" className="me-2" />
        </span>
        <a
          className="font--inter-semiBold"
          href={data?.facebook_link ?? "link"}
          target="_blank"
          rel="noreferrer"
        >
          {data?.facebook_link ?? "link"}
        </a>
      </p>
      <p className="font-16 font--inter-regular text-black mb-2">
        <span
          className="d-inline-block"
          style={{ width: "30px", textAlign: "center" }}
        >
          <img src="/assets/icons/t.png" alt="t" className="me-2" />
        </span>
        <a
          className="font--inter-semiBold"
          href={data?.twitter_link ?? "link"}
          target="_blank"
          rel="noreferrer"
        >
          {data?.twitter_link ?? "link"}
        </a>
      </p>
      <p className="font-16 font--inter-regular text-black mb-2">
        <span
          className="d-inline-block"
          style={{ width: "30px", textAlign: "center" }}
        >
          <img
            src="/assets/icons/link-blue.png"
            alt="link-blue"
            className="me-2"
          />
        </span>
        <a
          className="font--inter-semiBold"
          href={data?.link ?? "link"}
          target="_blank"
          rel="noreferrer"
        >
          {data?.link ?? "link"}
        </a>
      </p> */}
    </>
  );
}
