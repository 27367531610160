import { useState } from "react";
import { useAxios } from "../../../api/useAxios";

const useProgression = () => {
  const [progressionSteps, setProgressionSteps] = useState(0);

  const handleNextStep = (direction) => {
    const currentStep = !progressionSteps
      ? 1
      : progressionSteps + (direction === "next" ? 1 : -1);

    setProgressionSteps(currentStep);
  };

  const handleProgressionData = {
    get: (step) => JSON.parse(localStorage.getItem(step)),
    set: (step, data) => {
      localStorage.setItem(step, JSON.stringify(data));
    },
  };

  return {
    progressionSteps,
    setProgressionSteps,
    handleNextStep,
    handleProgressionData,
  };
};

export default useProgression;
