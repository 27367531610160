import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TitleBar from '../headers/title_bar';

export default function Header() {

    const headerData = {
        "subNotBold": "",
        "subBold": "Billing",
        "description": "Manage your billing here",
        "buttons" : true
    };

    return (
        <>
            <TitleBar data={headerData}></TitleBar>
        </>
    );
}