const convertHtmlToPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
  
    function traverse(element, indent = "") {
      let text = "";
  
      for (let i = 0; i < element.childNodes.length; i++) {
        const node = element.childNodes[i];
  
        if (node.nodeType === Node.TEXT_NODE) {
          text += `${indent}${node.textContent}\n`;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = node.tagName.toLowerCase();
  
          if (tagName === "p" || tagName === "div") {
            text += traverse(node, `${indent}\n`);
          } else if (tagName === "br") {
            text += `${indent}\n`;
          } else if (tagName === "hr") {
            text += `${indent}-----------------\n`;
          } else {
            text += traverse(node, indent);
          }
        }
      }
  
      return text;
    }
  
    const plainText = traverse(tempElement);
  
    tempElement.remove();
  
    return plainText;
  };
  
  export default convertHtmlToPlainText;
  