import { Button, Col, Image, Row } from "react-bootstrap";
import AccountPhotos from "./UploadPhotos";
import * as Actions from "../../../../redux/actions/userAction";
import { useEffect, useState } from "react";
import BasicInfo from "./BasicInfo";
import CompanyInfo from "./CompanyInfo";
import AdditionalInfo from "./AdditionalInfo";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../../api/useAxios";

const StepsForms = ({
  progressionSteps,
  setProgressionSteps,
  thumbnailImages,
  onAddCompanyImages,
  profile,
  profileImage,
  onProfileImageChange,
  headerImage,
  onHeaderImageChange,
  handleNextStep,
  handleProgressionData,
  actions,
  isLoggedIn,
  auth,
}) => {
  const navigate = useNavigate();
  const { post } = useAxios();

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.removeItem("step 1");
      localStorage.removeItem("step 2");
      localStorage.removeItem("step 3");
      localStorage.removeItem("step 4");
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn]);

  const [canProceed, setCanProceed] = useState(true);

  const handleSubmitOnboarding = async () => {
    const data = {
      profileImage,
      headerImage,
      ...handleProgressionData.get("step 2"),
      ...handleProgressionData.get("step 3"),
      ...handleProgressionData.get("step 4"),
    };

    data.role = "EMPLOYER";
    data.isSocialLogin = true;

    const signupStatus = await actions.signup(data);

    if (signupStatus === "ok") {
      await actions.loginUserSocial({
        email: data.email,
        password: data.password,
        token: auth.token,
        localId: auth.localId,
        providerId: auth.providerId,
      });

      await post("uploads/upload/image/profile", data.profileImage);
      await post("uploads/upload/image/cover", data.headerImage);

      const loginRes = await actions.editProfile(data);

      if (loginRes?.status === "ok") {
        navigate("/dashboard", { replace: true });
      }
    }
  };

  return (
    <>
      <form id="steps-account-form" className="my-4">
        <Col xs={12}>
          <Row className="form-group mb-3 d-flex align-items-start">
            {progressionSteps < 1 && (
              <AccountPhotos
                handleProgressionData={handleProgressionData}
                profileImage={profileImage}
                onProfileImageChange={onProfileImageChange}
                headerImage={headerImage}
                onHeaderImageChange={onHeaderImageChange}
              />
            )}

            {progressionSteps === 1 && (
              <BasicInfo
                canProceed={canProceed}
                setCanProceed={setCanProceed}
              />
            )}
            {progressionSteps === 2 && (
              <CompanyInfo
                canProceed={canProceed}
                setCanProceed={setCanProceed}
              />
            )}
            {progressionSteps === 3 && (
              <AdditionalInfo
                canProceed={canProceed}
                setCanProceed={setCanProceed}
              />
            )}
          </Row>
        </Col>
      </form>
      <Col className="d-flex justify-content-end">
        {progressionSteps > 0 && (
          <div className="px-2 py-3">
            <Button
              variant="dark"
              onClick={() => {
                setCanProceed(true);
                handleNextStep("previous");
              }}
            >
              Previous
            </Button>
          </div>
        )}

        {progressionSteps < 3 && (
          <div className="px-2 py-3">
            <Button
              variant="dark"
              onClick={() => {
                canProceed && handleNextStep("next");
              }}
            >
              Next
            </Button>
          </div>
        )}

        {progressionSteps >= 3 && (
          <div className="px-2 py-3">
            <Button variant="success" onClick={() => handleSubmitOnboarding()}>
              Update Account Profile
            </Button>
          </div>
        )}
      </Col>
    </>
  );
};

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepsForms);
