import { Button, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./assets/style.scss";

const BootstrapModal = ({
  title,
  children,
  footer,
  show,
  toggleShow,
  confirmCallback,
  footerBtnLeftText,
  footerBtnRightText,
  footerBtnLeftCallback,
  footerBtnRightCallback,
  footerBtnLeftVariant,
  footerBtnRightVariant,
  fullScreen,
}) => {
  return (
    <Modal
      className={`modal-container ${fullScreen && "--fullScreen"}`}
      show={show}
      onHide={() => toggleShow(false)}
      centered
    >
      {title && (
        <Modal.Header className="border-0 p-3" closeButton>
          <Modal.Title className="font--inter-bold">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Col>
            <Button
              className={`w-100 ${
                footerBtnLeftVariant === "dark"
                  ? "--variant-dark"
                  : "--variant-light"
              }`}
              onClick={() => footerBtnLeftCallback() || toggleShow(false)}
            >
              {footerBtnLeftText || "Cancel"}
            </Button>
          </Col>
          <Col>
            <Button
              className={`w-100 ${
                footerBtnRightVariant === "light"
                  ? "--variant-light"
                  : "--variant-dark"
              }`}
              onClick={confirmCallback || footerBtnRightCallback}
            >
              {footerBtnRightText || "Confirm"}
            </Button>
          </Col>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default BootstrapModal;
