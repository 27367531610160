import { Col, Container, Row } from "react-bootstrap";
import BuyDiamondTokenLayout from "./layouts/BuyDiamondTokenLayout";
import * as userActions from "../../redux/actions/userAction";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BuyTokenSignUpForm from "./forms/BuyTokenSignUpForm";
import BuyDiamondTokenForm from "./forms/BuyDiamonTokenForm";
import "./assets/style.css";
import StripeModals from "./StripeModals";
import { useForm } from "react-hook-form";
import useHandleStripe from "./hooks/useHandleStripe";
import useValidate from "./hooks/useValidate";
import useToken from "./hooks/useToken";

const BuyDiamondToken = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const isPremium = queryParams.get("premium") === "true";

  const navigate = useNavigate();

  const [isPurchasingToken, setIsPurchasingToken] = useState(false);
  const [isStripeVisible, setIsStripeVisible] = useState(true);

  const useTokenProps = useToken(props, isPremium);

  const {
    register,
    control,
    watch,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    errorMessage,
    setErrorMessage,
    handleValidateEmail,
    handleValidateUsername,
  } = useValidate();

  const handleStripe = useHandleStripe({
    ...props,
    amountToken: useTokenProps.amountToken,
    tokenPrice: useTokenProps.tokenPrice,
  });

  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] =
    useState("1");

  const handlePurchase = async () => {
    setIsPurchasingToken(true);
    setErrorMessage();
    let isValid = false;
    const data = getValues();

    isValid = await handleValidateUsername(data.username);
    isValid = await handleValidateEmail(data.email, data.password);

    isValid = await trigger();

    if (useTokenProps?.amountToken < 1) {
      setErrorMessage("Invalid amount of token");
      isValid = false;
    }

    if (isValid && !errorMessage) {
      data.phoneNumberDataId = selectedPhoneNumberPrefix;
      data.role = "EMPLOYER";

      const signupStatus = await props.userActions.signup(data, true);

      if (signupStatus === "ok") {
        const loginRes = await props.userActions.loginUser(
          {
            email: data.email,
            password: data.password,
          },
          true
        );

        loginRes?.status === "ok" && handleStripe.setShow(true);
      }
    }
  };

  useEffect(() => {
    props?.isLoggedIn &&
      !isPurchasingToken &&
      navigate("/billing", { replace: true, state: { isRedirected: true } });
  }, [props.isLoggedIn]);

  return (
    <BuyDiamondTokenLayout>
      <StripeModals
        {...props}
        {...handleStripe}
        amountToken={useTokenProps.amountToken}
        tokenPrice={useTokenProps.tokenPrice}
      />
      <Row>
        <Col xs={12} lg={6}>
          <BuyTokenSignUpForm
            {...props}
            register={register}
            handleSubmit={handleSubmit(handlePurchase)} // Pass handleSubmit with the handlePurchase function as an argument
            control={control}
            watch={watch}
            errors={errors}
            handlePurchase={handlePurchase}
            selectedPhoneNumberPrefix={selectedPhoneNumberPrefix}
            setSelectedPhoneNumberPrefix={setSelectedPhoneNumberPrefix}
          />
        </Col>
        <Col xs={12} lg={6}>
          <BuyDiamondTokenForm
            {...props}
            {...useTokenProps}
            handlePurchase={handlePurchase}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            isPremium={isPremium}
          />
        </Col>
      </Row>
    </BuyDiamondTokenLayout>
  );
};

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  status: props.jobReducer.status,
  tokens: props.userReducer.tokens,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyDiamondToken);
