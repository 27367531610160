import "./Fonts.css";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// modules
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import * as Actions from "./redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import utils
import Toast from "./components/Toast/Toast";
import history from "./utils/history";

// routes
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import ForgotPassword from "./routes/Forgot_password";
import ResetPassword from "./routes/Reset_password";
import Dashboard from "./routes/Dashboard";
import Jobs from "./routes/Jobs";
import Job from "./routes/Job";
import JobUser from "./routes/JobUser";
// import Creatives from "./routes/Creatives";
import Account from "./routes/Account";
import Billing from "./routes/Billing";
import TransactionHistory from "./routes/Transaction_history";
import PurchaseTokens from "./routes/Purchase_tokens";
import BuyDiamondToken from "./pages/BuyDiamondToken";

// components
import ScrollToTop from "./components/includes/ScrollToTop";
import DashboardLayout from "./components/layouts/DashboardLayout";
import CreateANewJob from "./routes/Create_a_new_job";
import Onboarding from "./pages/Onboarding";
import JobEdit from "./components/job/edit_job";

function App({ actions, isLoggedIn, user }) {
  useEffect(() => {
    if (isLoggedIn) {
      actions.getCurrentUser();
    }
  }, [isLoggedIn]);

  return (
    <Router history={history}>
      <ScrollToTop />
      <Toast />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/buy-job-token" element={<BuyDiamondToken />} />
        <Route
          element={isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />}
          path="/"
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/job/edit/:job_id" element={<JobEdit />} />
          <Route path="/job/:job_id" element={<Job />} />
          <Route path="/job/:job_id/:user_id" element={<JobUser />} />
          {/* <Route path="/creatives" element={<Creatives />} /> */}
          <Route path="/account" element={<Account />} />
          <Route path="/billing" element={<Billing user={user} />} />
          <Route
            path="/billing/transaction-history"
            element={<TransactionHistory />}
          />
          <Route
            path="/jobs/create-a-new-job"
            element={<CreateANewJob user={user} />}
          />
          <Route
            path="/jobs/create-a-new-job/:job_type"
            element={<CreateANewJob />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
