import Container from "react-bootstrap/Container";
import Header from "../components/billing/header";
// import Jobs from "../components/billing/jobs";
import TitleBar from "../components/headers/title_bar";
import Token from "../components/billing/token";
import JobTabs from "../components/purchase_tokens/job_tabs";
import Purchase from "../components/purchase_tokens/purchase";
import { UpdateActiveMenu } from "../utils/update_active";

const Billing = (props) => {
  UpdateActiveMenu("sl-billing");
  const jobTitleData = {
    subNotBold: "",
    subBold: "Purchase tokens",
    description: (
      <div>
        One token represents one job posting. Click{" "}
        <a
          href="https://www.cultcreative.asia/employers"
          target="_blank"
          className="text-decoration-none"
          rel="noreferrer"
        >
          here
        </a>{" "}
        to find out more about your token and its validity.
      </div>
    ),
    buttons: false,
  };
  return (
    <div className="content">
      <Container fluid>
        <Header></Header>
        <Token user={props.user} />
        {/* <Jobs/> */}
        <JobTabs />
        <TitleBar data={jobTitleData} buttons={false} />
        <Purchase />
      </Container>
    </div>
  );
};

export default Billing;
