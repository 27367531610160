import { toast } from "react-toastify";

export const successAlert = (msg, duration) => {
  toast.success(msg, {
    autoClose: duration || 5000,
  });
};
export const errorAlert = (error, duration) => {
  toast.error(error, {
    autoClose: duration || 5000,
  });
};
export const GetFileData = async (fileObject) => {
  const fileRead = await readFile(fileObject);
  const newFile = {
    url: fileRead,
    filename: fileObject.name,
  };
  return newFile;
};
export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};
