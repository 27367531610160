const ProgressionHeader = ({ progressionSteps }) => {
  return (
    <ul id="progressbar" className="text-center">
      <li
        className={`${
          progressionSteps && progressionSteps >= 1 && "active"
        } step0`}
      >
        <div className="my-3">Upload Photos</div>
      </li>
      <li
        className={`${
          progressionSteps && progressionSteps >= 2 && "active"
        } step0`}
      >
        <div className="my-3">Basic Info</div>
      </li>
      <li
        className={`${
          progressionSteps && progressionSteps >= 3 && "active"
        } step0`}
      >
        <div className="my-3">Company Details</div>
      </li>
      <li
        className={`${
          progressionSteps && progressionSteps >= 4 && "active"
        } step0`}
      >
        <div className="my-3">Additional Details</div>
      </li>
    </ul>
  );
};

export default ProgressionHeader;
