import Row from "react-bootstrap/Row";

import LoginLayout from "../components/layouts/LoginLayout";

const ResetPassword = () => {
    return (
        <>
            <LoginLayout>
                <form className={"ls-form login-form w-100"}>
                    <h3 className={"text-center mb-5 fw-700"}>Reset Password</h3>
                    <div className="form-group mb-3">
                        <input type="password" className="form-control" id="password" placeholder="New Password *" />
                    </div>
                    <div className="form-group mb-5">
                        <input type="password" className="form-control" id="confirm_password" placeholder="Confirm Password *" />
                    </div>
                    <Row className="m-0 mb-1 justify-content-center">
                        <button className="py-2 px-5 w-auto my-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700" type="submit">RESET PASSWORD</button>
                    </Row>
                </form>
            </LoginLayout>
        </>
    );

};

export default ResetPassword
