import { useEffect, useState } from "react";
import { useAxios } from "../api/useAxios";

const useListing = (user) => {
  const [phoneNumbersData, setphoneNumbersData] = useState([]);
  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] = useState(1);

  const [employerIndustries, setEmployerIndustries] = useState([]);
  const [selectedEmployerIndustry, setSelectedEmployerIndustry] = useState(252);

  const [employerCompanySizes, setEmployerCompanySizes] = useState([]);
  const [selectedEmployerCompanySize, setSelectedEmployerCompanySize] =
    useState();

  const { get } = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const listingData = await get("/utility/data/default", {});

        setphoneNumbersData(
          listingData?.phoneNumbersData?.sort((a, b) =>
            a.callingCode.localeCompare(b.callingCode)
          ) || []
        );

        setEmployerIndustries(
          listingData?.employerIndustries?.sort((a, b) =>
            a.value.localeCompare(b.value)
          ) || []
        );

        setEmployerCompanySizes(
          listingData?.employerCompanySizes?.sort((a, b) =>
            a.code.localeCompare(b.code)
          ) || []
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // user?.phoneNumber &&
    //   phoneNumbersData?.length > 0 &&
    //   setSelectedPhoneNumberPrefix(
    //     () =>
    //       phoneNumbersData.find((prefix) =>
    //         user.phoneNumber.startsWith(prefix.callingCode)
    //       ).id
    //   );

    user?.employerIndustryId &&
      employerIndustries?.length > 0 &&
      setSelectedEmployerIndustry(user.employerIndustryId);

    user?.employerCompanySizeId &&
      employerCompanySizes?.length > 0 &&
      setSelectedEmployerCompanySize(user.employerCompanySizeId);
  }, [user, phoneNumbersData, employerCompanySizes]);

  return {
    phoneNumbersData,
    selectedPhoneNumberPrefix,
    setSelectedPhoneNumberPrefix,
    employerIndustries,
    selectedEmployerIndustry,
    setSelectedEmployerIndustry,
    employerCompanySizes,
    selectedEmployerCompanySize,
    setSelectedEmployerCompanySize,
  };
};

export default useListing;
