import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  LOGOUT_SUCCESS,
  GET_USER_TOKENS,
  EMPTY_STATUS,
} from "../../constants/action-types";

const initialState = {
  user: {},
  employer: {},
  tokens: {
    balance: 0,
  },
  isLoggedIn: localStorage.getItem("token") ? true : false,
  status: "",
};

const userReducer = (state = initialState, action) => {
  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      isLoggedIn: false,
      status: LOGIN_SUCCESS,
    };
  }
  if (action.type === LOGIN_FAILURE) {
    return {
      ...state,
      isLoggedIn: false,
      status: LOGIN_FAILURE,
    };
  }
  if (action.type === AUTHENTICATE_USER_SUCCESS) {
    return {
      ...state,
      // employer: action.data.employer,
      employer: action.data.employerProfile,
      // user: action.data.user,
      user: action.data.employerProfile,
      isLoggedIn: true,
      status: AUTHENTICATE_USER_SUCCESS,
    };
  }
  if (action.type === AUTHENTICATE_USER_FAILURE) {
    return {
      ...state,
      isLoggedIn: false,
      status: AUTHENTICATE_USER_FAILURE,
    };
  }
  if (action.type === GET_USER_TOKENS) {
    return {
      ...state,
      tokens: {
        ...state.tokens,
        details: {
          Gold: action?.data?.find((t) => t.tokenId === 1) || {},
          Diamond: action?.data?.find((t) => t.tokenId === 2) || {},
        },
        balance: {
          gold: action?.data?.find((t) => t.tokenId === 1)?.totalTokens || 0,
          diamond: action?.data?.find((t) => t.tokenId === 2)?.totalTokens || 0,
        },
      },
    };
  }
  if (action.type === LOGOUT_SUCCESS) {
    return {
      ...state,
      isLoggedIn: false,
      user: {},
      employer: {},
      status: LOGOUT_SUCCESS,
    };
  }
  if (action.type === EMPTY_STATUS) {
    return {
      ...state,
      status: EMPTY_STATUS,
    };
  }
  return state;
};

export default userReducer;
