import Container from "react-bootstrap/Container";
import Form from "../components/account/form";
import Header from "../components/account/header";
import { UpdateActiveMenu } from "../utils/update_active";

const Creatives = () => {
  UpdateActiveMenu("sl-account");

  return (
    <div className="content">
      <Container fluid>
        <Header />
        <Form />
      </Container>
    </div>
  );
};

export default Creatives;
