import { GET_ACTIONS_REQUIRED, GET_WEEKLY_MEETINGS } from "../../constants/action-types";
import { request } from "../../services/http-service";

export const getActionsRequired = () => {
    return (dispatch) => {
        return request('cced/utility/actions-required', "get", '', true, dispatch).then((res) => {
            if (res.status === "ok") {
                dispatch({ type: GET_ACTIONS_REQUIRED, data: res['actionsRequired'] });
            }
        });
    };
};

export const getMeetings = (data) => {
    return (dispatch) => {
        return request('cced/utility/meetings', "POST",data, true, dispatch).then((res) => {
            if (res.status === "ok") {
                dispatch({ type: GET_WEEKLY_MEETINGS, data: res['meetings'] });
            }
        });
    };
};