import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Image } from "react-bootstrap";
import BootstrapCarousel from "../carousel/bootstrapCarousel";
import { Controller, useForm } from "react-hook-form";
import "./assets/style.css";
import useProfile from "./hooks/useProfile";
import useListing from "../../hooks/useListing";
import { useAxios } from "../../api/useAxios";
import { ReactCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import BootstrapModal from "../BootstrapModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  image64ToCanvas,
  getImage64Extension,
} from "../../utils/image64ToCanvas";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Form = ({ user, actions }) => {
  const { post } = useAxios();

  const [links, setLinks] = useState([]);
  const [existingLinks, setExistingLinks] = useState();

  const headerRef = useRef(null);
  const croppedImageRef = useRef(null);

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const [showHeaderCropModal, setShowHeaderCropModal] = useState(false);

  const handleOnCropComplete = async (crop) => {
    const canvasRef = croppedImageRef.current;
    const image64 = headerImage.filePreview;
    const fileExtension = getImage64Extension(image64);

    await image64ToCanvas(canvasRef, image64, crop);

    const croppedData = canvasRef.toDataURL("image/" + fileExtension);

    setHeaderThumbnail({
      ...headerImage,
      fileCroppedPreview: croppedData,
      fileData: croppedData.split(",")[1],
    });
  };

  const handleExistingLinkChange = (e, index) => {
    const newLinks = [...existingLinks];
    newLinks[index] = e.target.value;
    setExistingLinks(newLinks);
  };

  const handleLinkChange = (e, index) => {
    const newLinks = [...links];
    newLinks[index] = e.target.value;
    setLinks(newLinks);
  };

  const {
    thumbnailImages,
    onAddCompanyImages,
    profile,
    profileImage,
    onProfileImageChange,
    headerImage,
    onHeaderImageChange,
    setHeaderThumbnail,
  } = useProfile(user);

  const [crop, setCrop] = useState({ unit: "px" });

  useEffect(() => {
    !existingLinks && setExistingLinks(user?.additionalLinks?.split(", "));
  }, [user]);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const profileOptions = {
    username: { required: "Username is required" },
    companyName: { required: "Company Name is required" },
    phoneNumber: { required: "Phone Number is required" },
    websiteUrl: {
      validate: (url) => !url || /^(ftp|http|https):\/\/[^ "]+$/.test(url),
    },
  };

  const {
    phoneNumbersData,
    selectedPhoneNumberPrefix,
    setSelectedPhoneNumberPrefix,
    employerIndustries,
    selectedEmployerIndustry,
    setSelectedEmployerIndustry,
    employerCompanySizes,
    selectedEmployerCompanySize,
    setSelectedEmployerCompanySize,
  } = useListing(user);

  const handleEditProfile = async (data) => {
    data.phoneNumberDataId = parseInt(selectedPhoneNumberPrefix);
    data.profileImageUpload = profileImage;
    data.coverImageUpload = headerImage;
    data.industryId = selectedEmployerIndustry;
    data.companySizeId = selectedEmployerCompanySize;
    data.galleryImagesUrls = thumbnailImages;

    data.additionalLinks = existingLinks
      ? [...new Set([...existingLinks, ...links])].filter((f) => f !== "")
      : links;

    try {
      const galleryIds = (
        await post("uploads/upload/image/gallery", data.galleryImagesUrls)
      ).map((gallery) => gallery.uploadId);

      data.additionalImagesId = galleryIds;
    } catch (error) {
      throw new Error(error);
    }

    const editStatus = await actions.editProfile(data);

    if (editStatus === "ok") {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (user.name) {
      reset({
        companyName: user?.name,
        username: user?.username,
        businessAddress: user?.businessAddress,
        websiteUrl: user?.websiteUrl,
        companyOverview: user?.bio,
        phoneNumber: user?.phoneNumber,
      });
    }
  }, [user]);

  return (
    <div className="account-form-main bg-white border-radius-15 p-3 w-100 my-4">
      <p className="font--inter-semiBold font-24">Company</p>
      <form
        id="account-form"
        className="my-4"
        onSubmit={handleSubmit(handleEditProfile)}
      >
        <div className="px-5 m-5">
          <BootstrapModal
            fullScreen={true}
            toggleShow={setShowHeaderCropModal}
            title="Adjust Banner Image"
            children={
              <>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="--text-36 confirm-crop"
                  onClick={() => setShowHeaderCropModal(false)}
                />
                <ReactCrop
                  src={headerImage?.fileCroppedPreview}
                  crop={crop}
                  aspect={16 / 9}
                  // onChange={(crop, percentCrop) => handleOnCropChange(crop)}
                  onChange={(newCrop, newPercentCrop) =>
                    setCrop(newPercentCrop)
                  }
                  onComplete={handleOnCropComplete}
                  ruleOfThirds
                >
                  <Image src={headerImage.filePreview} alt={user.name} />
                </ReactCrop>
              </>
            }
            show={showHeaderCropModal}
            handleClose={() => setShowHeaderCropModal(false)}
          />
        </div>

        <Row className="mb-3">
          <Col xs={12} lg={4}>
            <label className="font--inter-semiBold text-black font-16 mb-2">
              Company Logo
            </label>
            <Row className="form-group mb-3 d-flex align-items-start flex-wrap">
              <Col xs={3}>
                <Image
                  src={profile?.profileImageUrl || "/assets/images/profile.png"}
                  alt={user.name}
                  width={60}
                  height={60}
                  className="rounded"
                />
              </Col>
              <Col xs={9}>
                <input
                  onChange={onProfileImageChange}
                  type="file"
                  className="hide"
                  accept="image/*"
                  id="profile-image"
                />
                <label
                  id="profile-image-label"
                  htmlFor="profile-image"
                  className="d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border border-3px-dashed cursor-pointer"
                  style={{
                    background: `url(${profileImage.filePreview})`,
                  }}
                >
                  {!profileImage && (
                    <>
                      <img
                        src="/assets/icons/camera.png"
                        alt="camera"
                        className="me-2"
                        id="profile-image-src"
                      />

                      <span>Company Logo</span>
                    </>
                  )}
                </label>
                <span>
                  Upload your logo in a square format! Maximum dimensions: 250px
                  x 250px
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={8}>
            <label className="font--inter-semiBold text-black font-16 mb-2">
              Banner Image
            </label>
            <div className="form-group mb-3">
              <Image
                height={250}
                src={profile?.profileBannerUrl || "/assets/images/header.png"}
                alt={user.name}
                className="m-0 mb-3 w-100"
              />
              <input
                ref={headerRef}
                onChange={(e) => onHeaderImageChange(e, setShowHeaderCropModal)}
                type="file"
                className="hide"
                accept="image/*"
                id="header-image"
              />
              <canvas
                ref={croppedImageRef}
                className="d-none"
                style={{
                  objectFit: "contain",
                }}
              />

              <label
                id="header-image-label"
                htmlFor="header-image"
                className="w-100 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border border-3px-dashed cursor-pointer"
                style={{
                  background: `url(${
                    headerImage?.fileCroppedPreview || headerImage.filePreview
                  })`,
                }}
              >
                {headerImage?.filePreview && (
                  <Button
                    className="btn-headerImage-resize"
                    onClick={() => setShowHeaderCropModal(true)}
                  >
                    Crop Banner
                  </Button>
                )}
                {!headerImage && (
                  <>
                    <img
                      src="/assets/icons/camera.png"
                      alt="camera"
                      className="me-2"
                    />
                    <span>Company Banner Image</span>
                  </>
                )}
              </label>
              <div className="d-flex align-items-center">
                <span className="d-block">Maximum dimension: 700x400</span>
                {headerImage?.filePreview && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setHeaderThumbnail("");
                      headerRef.current.value = null;
                    }}
                  >
                    <span className="mx-2 font--inter-bold">|</span>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-danger"
                    />{" "}
                    <span className="text-danger">Remove</span>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="form-group">
              <label
                className="font--inter-semiBold text-black font-16 mb-2"
                htmlFor="username"
              >
                Username
              </label>
              <input
                disabled
                type="text"
                className="form-control"
                id="username"
                placeholder="Enter your username here"
                {...register("username", profileOptions.username)}
              />
              <small className="text-danger">
                {errors?.username && errors.username.message}
              </small>
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label
                className="font--inter-semiBold text-black font-16 mb-2"
                htmlFor="phone_number"
              >
                Phone Number
              </label>
              <div className="input-group d-flex">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      name="phoneNumberDataId"
                      defaultValue={user?.phoneNumberDataId || 1}
                      value={selectedPhoneNumberPrefix}
                      className="select-user-profile-phone-prefix bg-transparent"
                      onChange={({ currentTarget: { value } }) => {
                        setSelectedPhoneNumberPrefix(value);
                        onChange(value);
                      }}
                    >
                      {phoneNumbersData?.length > 0 &&
                        phoneNumbersData
                          .sort((a, b) => a.callingCode - b.callingCode)
                          .map((phonePrefix) => (
                            <option key={phonePrefix.id} value={phonePrefix.id}>
                              {phonePrefix.callingCode}
                            </option>
                          ))}
                    </select>
                  )}
                  {...register(
                    "phoneNumberDataId",
                    profileOptions.phoneNumberDataId
                  )}
                />
                <input
                  type="text"
                  className="form-control"
                  id="phone-number"
                  placeholder="Phone Number"
                  defaultValue=""
                  {...register("phoneNumber", profileOptions.phoneNumber)}
                />
                <small className="text-danger">
                  {errors?.phoneNumber && errors.phoneNumber.message}
                </small>
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="company_name"
          >
            Company Name
          </label>
          <input
            type="text"
            className="form-control"
            id="company_name"
            placeholder="Enter your company name here"
            {...register("companyName", profileOptions.companyName)}
          />
          <small className="text-danger">
            {errors?.companyName && errors.companyName.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="business_address"
          >
            Business Address
          </label>
          <textarea
            className="form-control"
            id="business_address"
            placeholder="Enter your business address here"
            {...register("businessAddress", profileOptions.businessAddress)}
          />
        </div>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="website"
          >
            Website
          </label>
          <input
            type="text"
            className="form-control"
            id="websiteUrl"
            placeholder="Enter your website address here"
            {...register("websiteUrl", profileOptions.websiteUrl)}
          />
          <small className="text-danger">
            {errors.websiteUrl && "Invalid URL format"}
          </small>
        </div>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="industry"
          >
            Industry
          </label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <select
                name="employerIndustries"
                defaultValue={selectedEmployerIndustry}
                value={selectedEmployerIndustry}
                id="industry"
                className="select-user-profile-phone-prefix bg-transparent form-control"
                onChange={({ currentTarget: { value } }) => {
                  setSelectedEmployerIndustry(value);
                  onChange(value);
                }}
              >
                {employerIndustries?.length > 0 &&
                  employerIndustries.map((industry) => (
                    <option key={industry.id} value={industry.id}>
                      {industry.value}
                    </option>
                  ))}
              </select>
            )}
            {...register(
              "employerIndustries",
              profileOptions.employerIndustries
            )}
          />
        </div>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="company_size"
          >
            Company Size
          </label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <select
                name="employerCompanySizes"
                defaultValue={selectedEmployerCompanySize}
                value={selectedEmployerCompanySize}
                id="companySize"
                className="select-user-profile-phone-prefix bg-transparent form-control"
                onChange={({ currentTarget: { value } }) => {
                  setSelectedEmployerCompanySize(value);
                  onChange(value);
                }}
              >
                {employerCompanySizes?.length > 0 &&
                  employerCompanySizes.map((employerCompanySize) => (
                    <option
                      key={employerCompanySize.id}
                      value={employerCompanySize.id}
                    >
                      {employerCompanySize.code}
                    </option>
                  ))}
              </select>
            )}
            {...register(
              "employerCompanySizes",
              profileOptions.employerCompanySizes
            )}
          />
        </div>
        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor="company_overview"
          >
            Company Overview
          </label>
          <textarea
            className="form-control"
            id="company_overview"
            placeholder="Enter your company overview here"
            {...register("companyOverview", profileOptions.companyOverview)}
          />
        </div>
        <div className="form-group mb-3 d-flex flex-column">
          <BootstrapCarousel
            items={profile?.galleryImagesUrls?.map((imgSrc) => {
              return { imgSrc };
            })}
          />

          <label
            className="font--inter-semiBold text-black font-16 mb-2 mt-5"
            htmlFor="gallery"
          >
            Gallery
          </label>

          <div className="">
            <input
              onChange={onAddCompanyImages}
              multiple
              type="file"
              className="hide"
              accept="image/*"
              id="gallery-images"
            />
            <label
              htmlFor="gallery-images"
              className="d-flex align-items-center justify-content-center border-3-solid-blue rounded p-5 w-100 cursor-pointer"
            >
              <img
                src="/assets/icons/camera.png"
                alt="camera"
                className="me-2"
              />{" "}
              Add Photo
            </label>
          </div>
        </div>
        <div className="form-group mb-3">
          <Row className="mb-3">
            {thumbnailImages.map((image, index) => {
              return (
                <Col xs={2} sm={2} className="mb-4">
                  <img
                    src={image.filePreview}
                    alt="gallery preview"
                    className="carousel-item-preview w-100"
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <div />

        <div className="form-group mb-3">
          <label
            className="font--inter-semiBold text-black font-16 mb-2"
            htmlFor=""
          >
            Additional Links
          </label>
          <div>
            <div className="form-group mb-3">
              {existingLinks?.map((link, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control my-2"
                  placeholder="Enter additional link here"
                  value={link}
                  onChange={(e) => handleExistingLinkChange(e, index)}
                />
              ))}
              {links.map((link, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control my-2"
                  placeholder="Enter additional link here"
                  value={link}
                  onChange={(e) => handleLinkChange(e, index)}
                />
              ))}
            </div>
          </div>
          <div>
            <button
              onClick={handleAddLink}
              type="button"
              id="add-link-button"
              className="text-black text-decoration-none p=0 bg-transparent border-0 font-14"
            >
              <img src="/assets/icons/plus.png" alt="plus" /> Add Link
            </button>
          </div>
        </div>

        <Row className="m-0 mb-1 justify-content-center">
          <Button
            disabled={isSubmitting}
            className={`save-account-btn text-decoration-none ${
              isSubmitting ? "bg-secondary" : "blue-bg"
            } px-3 py-2 text-white rounded font--inter-semiBold border-secondary`}
            type="submit"
          >
            Save
          </Button>
        </Row>
      </form>
    </div>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
