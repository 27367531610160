import {
  GET_ALL_JOBS,
  GET_TOKEN_DETAILS,
  CREATE_JOB_SUCCESS,
  EMPTY_STATUS,
  CREATE_JOB_FAILURE,
  FILTER_DROPDOWN_DATA,
  PURCHASE_TOKEN_FAILURE,
  PURCHASE_TOKEN_SUCCESS,
  SELECTED_JOB_SUCCESS,
  SELECTED_JOB_FAILURE,
  CLOSE_JOB_SUCCESS,
  CLOSE_JOB_FAILURE,
  GET_STATUS_LIST,
  JOB_STATUS_SUCCESS,
  JOB_STATUS_FAILURE,
  FILTER_DROPDOWN_COUNTRY,
} from "../../constants/action-types";

const initialState = {
  jobListing: [],
  status: "",
  dropdownFilters: "",
  statusListing: [],
  tokenDetails: "",
};

const jobReducer = (state = initialState, action) => {
  if (action.type === GET_ALL_JOBS) {
    return {
      ...state,
      jobListing: action.data,
    };
  }
  if (action.type === GET_TOKEN_DETAILS) {
    return {
      ...state,
      tokenDetails: action.data,
    };
  }
  if (action.type === GET_STATUS_LIST) {
    return {
      ...state,
      statusListing: action.data,
    };
  }
  if (action.type === CREATE_JOB_SUCCESS) {
    return {
      ...state,
      status: CREATE_JOB_SUCCESS,
    };
  }
  if (action.type === CREATE_JOB_FAILURE) {
    return {
      ...state,
      status: CREATE_JOB_FAILURE,
    };
  }
  if (action.type === FILTER_DROPDOWN_DATA) {
    return {
      ...state,
      dropdownFilters: action.data,
      status: FILTER_DROPDOWN_DATA,
    };
  }
  if (action.type === FILTER_DROPDOWN_COUNTRY) {
    return {
      ...state,
      dropdownFilters: {
        ...state.dropdownFilters,
        countries: action.data,
      },
      status: FILTER_DROPDOWN_COUNTRY,
    };
  }
  if (action.type === PURCHASE_TOKEN_SUCCESS) {
    return {
      ...state,
      status: PURCHASE_TOKEN_SUCCESS,
    };
  }
  if (action.type === PURCHASE_TOKEN_FAILURE) {
    return {
      ...state,
      status: PURCHASE_TOKEN_FAILURE,
    };
  }
  if (action.type === EMPTY_STATUS) {
    return {
      ...state,
      status: EMPTY_STATUS,
    };
  }
  if (action.type === SELECTED_JOB_SUCCESS) {
    return {
      ...state,
      selectedJob: action.data,
      status: SELECTED_JOB_SUCCESS,
    };
  }
  if (action.type === SELECTED_JOB_FAILURE) {
    return {
      ...state,
      status: SELECTED_JOB_FAILURE,
    };
  }
  if (action.type === CLOSE_JOB_SUCCESS) {
    return {
      ...state,
      status: CLOSE_JOB_SUCCESS,
    };
  }
  if (action.type === CLOSE_JOB_FAILURE) {
    return {
      ...state,
      status: CLOSE_JOB_FAILURE,
    };
  }
  if (action.type === JOB_STATUS_SUCCESS) {
    return {
      ...state,
      status: JOB_STATUS_SUCCESS,
    };
  }
  if (action.type === JOB_STATUS_FAILURE) {
    return {
      ...state,
      status: JOB_STATUS_FAILURE,
    };
  }
  return state;
};

export default jobReducer;
