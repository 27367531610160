import axios from "axios";
import store from "../redux/store";
import { logoutAction } from "../redux/actions/userAction";
import { errorAlert } from "../constants/helper";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const middleware = (config) => {
  try {
    const token = localStorage.getItem("token") || null;

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  } catch (error) {
    throw error;
  }
};

axiosInstance.interceptors.request.use(middleware);

export const useAxios = () => {
  try {
    const get = async (url, options) => {
      const response = await axiosInstance.get(url, options);
      return response.data;
    };

    const post = async (url, options) => {
      options = !options ? {} : options;

      const response = await axiosInstance.post(url, options);

      if (response.data.errorMessage === "Token is invalid") {
        errorAlert('Session expired, kindly relog-in')
        store.dispatch(logoutAction());
      } else {
        return response.data;
      }
    };

    return {
      get,
      post,
    };
  } catch (error) {
    throw error;
  }
};
