export function allJobs() {
    const listing = [
        {
            id: 1,
            name: "Product Manager",
            type: "Full Time",
            status: "Live",
            noOfApplicants: 12,
            expiresIn: 20,
            count: {
                pendingReview: 10,
                shortlisted: 15,
                interviewStage: 20,
                offered: 25,
                Unsuccessful: 5
            },
            upDown: {
                newApplicants: {
                    upDown: "Up",
                    count: 70
                },
                views: {
                    upDown: "Down",
                    count: 35
                },
                shares: {
                    upDown: "Down",
                    count: 14
                },
            },
        },
        {
            id: 2,
            name: "UI/UX Designer",
            type: "Freelance",
            status: "Paused",
            noOfApplicants: 8,
            expiresIn: 2,
            count: {
                pendingReview: 10,
                shortlisted: 15,
                interviewStage: 20,
                offered: 25,
                Unsuccessful: 5
            },
            upDown: {
                newApplicants: {
                    upDown: "Up",
                    count: 90
                },
                views: {
                    upDown: "Up",
                    count: 50
                },
                shares: {
                    upDown: "Up",
                    count: 20
                },
            },
        },
        {
            id: 3,
            name: "UI/UX Designer",
            type: "Freelance",
            status: "Closed",
            noOfApplicants: 8,
            expiresIn: 45,
            count: {
                pendingReview: 10,
                shortlisted: 15,
                interviewStage: 20,
                offered: 25,
                Unsuccessful: 5
            },
            upDown: {
                newApplicants: {
                    upDown: "Up",
                    count: 90
                },
                views: {
                    upDown: "Up",
                    count: 50
                },
                shares: {
                    upDown: "Up",
                    count: 20
                },
            },
        },
        {
            id: 4,
            name: "UI/UX Designer",
            type: "Freelance",
            status: "Live",
            noOfApplicants: 8,
            expiresIn: 12,
            count: {
                pendingReview: 10,
                shortlisted: 15,
                interviewStage: 20,
                offered: 25,
                Unsuccessful: 5
            },
            upDown: {
                newApplicants: {
                    upDown: "Up",
                    count: 90
                },
                views: {
                    upDown: "Up",
                    count: 50
                },
                shares: {
                    upDown: "Up",
                    count: 20
                },
            },
        }
    ];

    return listing;
}