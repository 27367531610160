import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import * as Actions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoginLayout from "../../components/layouts/LoginLayout";
import SocialLogins from "../../components/commons/SocialLogins";
import { Image } from "react-bootstrap";
import "./assets/style.scss";

const Login = ({ actions, isLoggedIn, status }) => {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleLogin = async (data) => {
    await actions.loginUser(data);
    actions.getUserTokens();
  };
  const loginOptions = {
    email: { required: "Email is required" },
    password: {
      required: "Password is required",
    },
  };
  /*  const getToken = () => {
       const token = localStorage.getItem("token");
       if(token){
           navigate('/', { replace: true });
       }
   }; */
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn]);

  return (
    <>
      <LoginLayout>
        <div className="--position-absolute-top-center w-100 text-center --pt-33 d-flex justify-content-center align-items-center">
          <Link
            to={process.env.REACT_APP_CCWL_REDIRECT_URI}
            className="text-decoration-none"
          >
            <Image src={require("./assets/images/icons/ico-profile.png")} />
            <span className="text-switch-creative">
              Click here to switch to Creative
            </span>
          </Link>
        </div>
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="ls-form login-form w-100"
        >
          <h3 className="text-start mb-5 fw-700 --font-stretch-pro --text-36">
            Login
          </h3>
          <div className="form-group mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              id="email_username"
              placeholder="Email *"
              {...register("email", loginOptions.email)}
            />
            <small className="text-danger">
              {errors?.email && errors.email.message}
            </small>
          </div>
          <div className="form-group mb-4">
            <input
              className="form-control"
              id="password"
              placeholder="Password *"
              type="password"
              name="password"
              {...register("password", loginOptions.password)}
            />
            <small className="text-danger">
              {errors?.password && errors.password.message}
            </small>
          </div>
          <Row className="m-0 mb-5">
            <Col className="p-0">
              <div className="form-group">
                <input
                  className="me-1"
                  type="checkbox"
                  name="remember_me"
                  id="remember_me"
                  value="1"
                />
                <label htmlFor="remember_me" className="text-cc-grey fw-700">
                  Remember Me
                </label>
              </div>
            </Col>
            <Col className="text-end p-0">
              <Link className="text-black fw-700" to="/forgot-password">
                Forgot Password?
              </Link>
            </Col>
          </Row>
          <Row className="m-0 mb-1 social-login-container justify-content-center">
            <SocialLogins
              type="login"
              actions={actions}
              setErrorMessage={setErrorMessage}
            />
            <span className="text-danger --text-16 text-center pt-2">
              {errorMessage}
            </span>
          </Row>
          <Row className="m-0 mb-1 justify-content-center">
            <button
              className="py-2 px-5 w-auto my-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700"
              type="submit"
            >
              Login
            </button>
          </Row>
          <Row className="m-0 mb-3 justify-content-center">
            <p className="text-secondary text-center fw-700 p-0">
              Don't have an account?{" "}
              <Link className="text-black text-decoration-none" to="/signup">
                Sign Up Now
              </Link>
            </p>
          </Row>
        </form>
      </LoginLayout>
    </>
  );
};
const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  status: props.userReducer.status,
  tokens: props.userReducer.tokens,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
