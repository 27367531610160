export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_TOKEN_DETAILS = "GET_TOKEN_DETAILS";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EMPTY_STATUS = "EMPTY_STATUS";
export const FILTER_DROPDOWN_DATA = "FILTER_DROPDOWN_DATA";
export const PURCHASE_TOKEN_SUCCESS = "PURCHASE_TOKEN_SUCCESS";
export const PURCHASE_TOKEN_FAILURE = "PURCHASE_TOKEN_FAILURE";
export const SELECTED_JOB_SUCCESS = "SELECTED_JOB_SUCCESS";
export const SELECTED_JOB_FAILURE = "SELECTED_JOB_FAILURE";
export const GET_ALL_CREATIVES = "GET_ALL_CREATIVES";
export const CREATIVES_PAGINATION = "CREATIVES_PAGINATION";
export const CLOSE_JOB_SUCCESS = "CLOSE_JOB_SUCCESS";
export const CLOSE_JOB_FAILURE = "CLOSE_JOB_FAILURE";
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const JOB_STATUS_SUCCESS = "JOB_STATUS_SUCCESS";
export const JOB_STATUS_FAILURE = "JOB_STATUS_FAILURE";
export const GET_ACTIONS_REQUIRED = "GET_ACTIONS_REQUIRED";
export const GET_WEEKLY_MEETINGS = "GET_WEEKLY_MEETINGS";
export const GET_TRANSACTIONS_HISTORY = "GET_TRANSACTIONS_HISTORY";
export const GET_USER_TOKENS = "GET_USER_TOKENS";
export const FILTER_DROPDOWN_COUNTRY = "FILTER_DROPDOWN_COUNTRY";
export const FILTER_DROPDOWN_LOCATION = "FILTER_DROPDOWN_LOCATION";
export const GET_ALL_PHONE_PREFIXES = "GET_ALL_PHONE_PREFIXES";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_JOB_FAILURE = "EDIT_JOB_FAILURE";
