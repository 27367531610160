import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const OnboardingLayout = ({ children }) => {
  return (
    <Container fluid className="blue-bg">
      <Row
        className="vh-100 justify-content-center align-items-center"
        style={{
          overflow: "auto",
        }}
      >
        <Col
          xs={10}
          className="py-5 d-flex justify-content-center align-items-center"
          style={{
            maxWidth: 900,
          }}
        >
          <div className="bg-white px-4 py-5 border-radius-25 w-100 align-items-center">
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OnboardingLayout;
