import { Col } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import useProgression from "../../hooks/useProgression";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../../redux/actions/userAction";

const CompanyInfo = ({ setCanProceed }) => {
  const { handleProgressionData } = useProgression();

  const [companyInfoData, setCompanyInfoData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const companyNameRef = useRef(null);

  const handleResetError = () => {
    setErrorMessage();
    setCanProceed(true);
  };

  const handleValidateCompanyName = async () => {
    const companyName = companyNameRef.current.value;

    if (!companyName) {
      setErrorMessage((prevErrorMessage) => ({
        ...prevErrorMessage,
        companyName: "Company name is required",
      }));

      return;
    } else {
      const { companyName, ...updatedErrorMessage } = errorMessage;
      setErrorMessage(updatedErrorMessage);
    }
  };

  useEffect(() => {
    const step3Data = handleProgressionData.get("step 3");
    !companyInfoData && setCompanyInfoData(step3Data);

    companyInfoData &&
      handleProgressionData.set("step 3", {
        ...step3Data,
        ...companyInfoData,
        companyInfoData: companyInfoData,
      });
  }, [companyInfoData]);

  useEffect(() => {
    errorMessage && setCanProceed(false);
  }, [errorMessage]);

  useEffect(() => {
    handleValidateCompanyName();
  }, [companyNameRef?.current?.value]);

  return (
    <Col xs={12} className="p-5 justify-content-center">
      <div className="form-group mb-3">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Company Name
        </label>
        <input
          ref={companyNameRef}
          type="text"
          className="form-control"
          placeholder="Company Name *"
          name="companyName"
          value={companyInfoData?.companyName || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setCompanyInfoData({ ...companyInfoData, companyName: value });
          }}
        />

        <div className="container-errorMessage text-danger --text-12 pt-2">
          {errorMessage?.companyName}
        </div>
      </div>

      <div className="form-group mb-3 bg-whitse">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Business Address
        </label>
        <textarea
          className="form-control"
          id="business_address"
          placeholder="Enter your business address here"
          value={companyInfoData?.businessAddress || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setCompanyInfoData({ ...companyInfoData, businessAddress: value });
          }}
        />
      </div>

      <div className="form-group mb-3 bg-whitse">
        <label className="text-black font-14 mb-1" htmlFor="form-control">
          Company Overview
        </label>
        <textarea
          className="form-control"
          id="company_overview"
          placeholder="Enter your company overview here"
          value={companyInfoData?.companyOverview || ""}
          onChange={({ currentTarget: { value } }) => {
            handleResetError();
            setCompanyInfoData({ ...companyInfoData, companyOverview: value });
          }}
        />
      </div>
    </Col>
  );
};

const mapStateToProps = (props) => ({
  user: props.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
